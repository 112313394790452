<template>
  <div class="tank">
    <van-nav-bar
      title="评审会内容"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="contentWrap">
      <div class="blurInfo"></div>
      <div class="info">
        <div class="baseInfo">
          <img :src="info.cover" alt="头像" class="avatar">
          <div class="infoContent">
            <div class="infoName">
              <strong>{{info.name}}</strong>
            </div>
            <div class="infoPart">
              <span>{{tankInfo.name}}</span>
            </div>
            <div class="infoHonar">
              <!-- <span>18人预约过</span> -->
              <span></span>
              <span>{{info.price}}元</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item" style="margin-top: 24px;">
        <h4>评审会详情</h4>
        <p class="intro" v-html="info.desc"></p>
      </div>
      <div class="line"></div>
      <div class="orderTime">
        <div class="orderDetail">
          <ul>
            <li>您要预约的项目为：{{info.name}}</li>
            <li>专家为：{{tankInfo.name}}</li>
            <li class="textArea inputArea">
              <van-field 
                v-model="orderInfo.contacts" 
                type="text" 
                label-width="60" 
                label="联系人："/>
            </li>
            <li> 
              <van-field 
                label-width="60" 
                v-model="userInfo.phoneNumber" 
                type="tel" 
                @blur="handleEditPhone(1)"
                label="手机号为" 
                clearable
                maxlength="11"
                placeholder="请输入手机号" 
                v-if="phoneDisabled"/>
              <p v-else>手机号为：{{userInfo.phoneNumber}}</p>
              <van-icon name="edit" @click="handleEditPhone(0)" v-if="!phoneDisabled"/>
              <!-- <van-icon name="success" @click="handleEditPhone(1)" v-else /> -->
            </li>
            <li class="textArea">
              <van-field 
                label-width="60"
                v-model="orderInfo.note" 
                rows="4"
                autosize
                maxlength="50"
                type="textarea"
                placeholder="请在此输入评审会时间、地点评审会内容。" 
                label="写备注："/>  
            </li>
          </ul>
          <div class="orderBtn" @click="handleOrder">现在预约</div>
          <div class="orderNote">
            <p>预约成功后请保持电话畅通</p>
            <p>24小时内客服联系您的手机号</p>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item" style="margin-top: 0">
        <div>
          <h4>同类型专家推荐</h4>
        </div>
        <ul class="enjoy">
          <li v-for="(item,index) in recommendList" :key="index" @click="toDetail(item.id)">
            <img :src="item.photo" alt=""> 
            <div>
              <p class="peopleName">{{item.name}}</p>
              <div class="honorList">
                <p>{{item.titleCert}}</p>
                <p>{{item.practiceCert}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, NavBar, Calendar, DatetimePicker, Popup, Toast, Field } from 'vant';
import { Create } from '@/api/Order'
import { GetRecommendList } from '@/api/Expert'
import Vue from 'vue';
import { isMobile } from '@/util/validate'
Vue.use(Toast);
export default {
  name: 'Mine',
  data(){
    return{
      popShow: false,
      companyList: [{
        name: '吴彦祖',
        honarList: [
          {
            name: '电气工程师'
          },
          {
            name: '电气工程师'
          }
        ]
      }, {
        name: '吴彦祖',
        honarList: [
          {
            name: '电气工程师'
          },
          {
            name: '电气工程师'
          }
        ]
      }, {
        name: '吴彦祖',
        honarList: [
          {
            name: '电气工程师'
          },
          {
            name: '电气工程师'
          }
        ]
      }, {
        name: '吴彦祖',
        honarList: [
          {
            name: '电气工程师'
          },
          {
            name: '电气工程师'
          }
        ]
      }],
      orderInfo: {
        phone: '',
        note: '',
        contacts: ''
      },
      tankId: '',
      info: {},
      userInfo:{},
      tankInfo: {},
      recommendList: [],
      phoneDisabled: false
    }
  },
  computed: {
    user_info(){
      return JSON.parse(this.$store.state.order.user_info)
    }
  },
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    [Toast.name]: Toast,
    [Field.name]: Field
  },
  created(){
    this.tankId = this.$route.query.id;
    this.info = JSON.parse(this.$store.state.order.tankProduct);
    this.tankInfo = JSON.parse(this.$store.state.order.tankInfo);
    this.getRecommendList()
    this.userInfo.phoneNumber = JSON.parse(JSON.stringify(this.user_info)).phoneNumber
  },
  methods: {
    getRecommendList(){
      let params = {
        TypeId: this.tankInfo.subTypes[0].typeId,
        MaxResultCount:2
      }
      GetRecommendList(params).then(res=>{
        this.recommendList = res.items
      }).catch(err=>{
      })
    },
    // 点击图标更改手机号
    handleEditPhone(index){
      if(index===1){
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        let phone = this.userInfo.phoneNumber
        if(!reg.test(phone)){
          Toast.fail('请输入正确的手机号');
          return
        }
      }
      this.phoneDisabled = !this.phoneDisabled
    },
    // 返回上级页面
    onClickLeft(){
      this.$router.back()
    },
    // 立即预约
    handleOrder(){
      if(this.orderInfo.contacts===''){
        Toast.fail("请填写联系人")
        return
      }
      if(this.userInfo.phoneNumber===''){
        Toast.fail("请填写联系人手机号")
        return
      }
      if(!isMobile(this.userInfo.phoneNumber)){
        Toast.fail('请输入正确的手机号');
        return false
      }
      if(this.orderInfo.note===''){
        Toast.fail("请填写备注")
        return
      }
      let params = {
        productId: this.info.id,
        expertId: this.tankInfo.id,
        contact: this.orderInfo.contacts,
        contactPhone: this.userInfo.phoneNumber,
        remark: this.orderInfo.note
      }
      Create(params).then((res) => {
        Toast.success('预约成功，请等待客服联系您确认预约')
        let second = 2;
        const timer = setInterval(() => {
          second--;
          if (second) {
          } else {
            clearInterval(timer);
            // 手动清除 Toast
            Toast.clear();
            this.$router.push("/myOrder")
          }
        }, 1000);
      }).catch((err) => {
        Toast('预约失败')
      });
    },
    // 跳转到详情
    toDetail(id){
      this.$router.push({
        name: 'TankDetail',
        query:{
          id: id,
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.tank{
  background: #fff;
  min-height: 100vh;
  .contentWrap{
    padding: 30px 0 30px;
    position: relative;
    .blurInfo{
      background: #E4E4E4;
      box-shadow: 0px 5px 4px 0px #E4E4E4;
      border-radius: 14px;
      border: 1px solid #F5F5F5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: 16px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 144px;
    }
    .info{
      z-index: 99;
      position: relative;
      border-radius: 14px;
      padding: 23px 0;
      box-sizing: border-box;
      margin: 0 18px;
      background:#FFFFFF url('../../assets/img/home/badge.png') no-repeat 96% 60%;
      .transpond{
        position: absolute;
        color: #868A8B;
        font-size: 20px;
        top: 8px;
        right: 8px;
      }
      .baseInfo{
        display: flex;
        padding: 0 20px 0 13px;
        align-items: center;
        .avatar{
          width: 83px;
          height: 83px;
          margin-right: 8px;
          border-radius: 8px;
        }
        .infoContent{
          flex: 1;
          font-size: 12px;
          letter-spacing: 1px;
          .infoName{
            display: flex;
            align-items: center;
            strong{
              font-size: 18px;
              color: #202324;
            }
          }
          .infoPart{
            margin: 4px 0 16px;
            font-size: 13px;
            color: #4E4E4E;
          }
          .infoHonar{
            display: flex;
            justify-content: space-between;
            span:nth-child(1){
              color: #575757;
            }
            span:nth-child(2){
              color: #DC332E;
            }
          }
        }
      }
      .infoExplain{
        display: flex;
        color: #636060;
        margin: 8px 0 12px 0;
        justify-content: space-between;
        padding: 0 30px;
        li{
          width: 62px;
          text-align: center;
          .times{
            font-size: 10px;
            color: #202324;
            margin: 12px 0 4px;
            span{
              font-size: 18px;
            }
          }
          .expound{
            color: #868A8B;
            font-size: 13px;
          }
        }
      }
    }
    .line{
      height: 13px;
      background: #F3F8FB;
    }
    .item{
      margin-top: 30px;
      background: #fff;
      padding: 12px 18px;
      box-sizing: border-box;
      h4{
        font-weight: normal;
        line-height: 34px;
        color: #868A8B;
        font-size: 15px;
      }
      .intro{
        font-size: 13px;
        color: #202324;
        font-weight: 300;
        letter-spacing: .4px;
        line-height: 20px;
        margin-top: 4px;
      }
      .enjoy{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          width: 160px;
          margin-bottom: 15px;
          height: 97px;
          display: flex;
          align-items: center;
          background: #fff;
          border-radius: 14px;
          padding: 0 8px;
          box-sizing: border-box;
          box-shadow: 0px 5px 4px 0px rgba(228,228,228,0.5);
          overflow: hidden;
          img{
            width: 58px;
            height: 58px;
            border-radius: 58px;
            margin-right: 8px;
          }
          p{
            font-size: 13px;
            color: #2B2E2F;
            letter-spacing: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .peopleName{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 4px;
          }
          .honorList{
            font-size: 13px;
            font-weight: 300;
            line-height: 16px;
            height: 30px;
            overflow: hidden;
          }
        }
      }
      .join{
        li{
          display: flex;
          background: #F1F6FB;
          border-radius: 14px;
          border: 1px solid #F1F6FB;
          padding: 5px 8px;
          align-items: center;
          img{
            width:83px;
            height: 83px;
            border-radius: 12px;
            margin-right: 18px;
          }
          .joinDetail{
            flex: 1;
            h6{
              line-height: 24px;
              color: #202324;
              font-size: 18px;
            }
            p{
              color: #4E4E4E;
              font-size: 13px;    
              line-height: 18px;
              margin-top: 2px;
            }
            div{
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              span:nth-child(1){
                color: #DC332E;
              }
              span:nth-child(2){
                background: #FAF4DC;
                border-radius: 8px;
                color: #F2916F;
                font-size: 10px;
                padding: 4px 8px;            
                font-weight: 600;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
    .orderTime{
      padding: 28px 29px;
      .orderDetail{
        margin-top: 4px;
        ul{
          li{
            border-bottom: 1px solid #E2F4FF;
            height: 46px;
            line-height: 46px;
            color: #575757;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px !important;
            /deep/.van-icon{
              font-size: 24px;
              color: #bdbde1;
            }
            /deep/.van-cell::after{
              border: 0;
            }
            /deep/.van-cell{
              font-size: 14px !important;
              padding-left: 0;
            }
          }
          .textArea{
            height: auto;
            line-height: auto;
            /deep/.van-field__control{
              border: 1px solid #E2E2E2;
              padding: 4px 4px;
              border-radius: 4px;
            }
          }
          .inputArea{
            /deep/.van-field__label{
              display: flex;
              align-items: center;
            }
          }
          
        }
        .orderBtn{
          width: 322px;
          height: 49px;
          background: #FAF4DC;
          border-radius: 4px;
          margin: 18px auto;
          font-size: 17px;
          font-weight: 500;
          color: #F2916F;
          line-height: 49px;
          text-align: center;
        }
        .orderNote{
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          color: #575757;
          line-height: 18px;
          margin-top: 14px;
          width: 60%;
          margin-left: 20%;
        }
      }
    }
  }
}
</style>