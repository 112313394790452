/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 综合搜索接口
 * request: /api/v1/home/search
 * method: GET
 * @param filter - 
 * eg.
 */
export const MagicSearch = function(queryParams) {
  let path = '/api/v1/home/search';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
export const MagicAppInfo = function() {
  let path = '/api/v1/home/app-info';
  return request({
    url: path,
    method: 'GET',
  })
}