<template>
  <div class="recommend"> 
    <header>
      <h3>评审专家库</h3>
      <van-icon name="search" @click="toSearch"/>
      <div class="floatWrap" @click="toAcquisitionLetter"></div>
    </header>
    <div class="companyWrap">
      <ul class="companyList">
        <li v-for="(item,index) in companyList" :key="index" @click="toDetail3(item)">
          <img :src="item.icon" alt="">
          <div>
            <p>{{item.name}}</p>
            <p class="englishName">{{item.enName}}</p>
          </div>
        </li>
      </ul>
      <div class="homeBlock">
        <div class="title">
          <h4>评审案例</h4>
          <span @click="LookMore('case')">更多</span>
        </div>
        <ul class="enjoy">
          <li v-for="(item,index) in reviewList" :key="index" @click="toDetail(item.id)">
            <img :src="item.cover" alt="">
            <p>{{item.name}}</p>
          </li>
        </ul>
      </div>
      <div class="homeBlock">
        <div class="title">
          <h4>名企推荐</h4>
          <span @click="LookMore('recommend')">更多</span>
        </div>
        <ul class="enjoy">
          <li v-for="(item,index) in enterpriseList" :key="index" @click="toDetail2(item.id)">
            <img :src="item.cover" alt="">
            <p>{{item.name}}</p>
          </li>
        </ul>
      </div>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { Icon } from 'vant';
import { GetRecommendList } from '@/api/Enterprise';
import { GetList as GetReviewList } from '@/api/Review';
import { GetTypes } from '@/api/Expert'
export default {
  name: 'Home',
  data(){
    return{
      active: 0,
      companyList: [],
      enterpriseList: [],
      reviewList: []
    }
  },
  created(){
    this.getType()
    this.getEnterpriseList()
    this.getReviewList()
  },
  components: {
    TabBar,
    [Icon.name]: Icon,
  },
  methods:{
    // 跳转到征集函
    toAcquisitionLetter(){
      this.$router.push("/acquisitionLetter")
    },
    getReviewList(){
      GetReviewList({MaxResultCount:4}).then((res)=>{
        this.reviewList = res.items
      }).catch(err=>{
      })
    },
    getEnterpriseList(){
      GetRecommendList({MaxResultCount:4}).then((res)=>{
        this.enterpriseList = res.items
      }).catch(err=>{
      })
    },
    getType(){
      GetTypes().then(res=>{
        this.companyList = res
      }).catch(err=>{
      })
    },
    // 跳转到搜索页面
    toSearch(){
      this.$router.push("/search")
    },
    LookMore(txt){
      if(txt==='case'){
        this.$router.push("/case")
      }else if(txt==='recommend'){
        this.$router.push("/recommend")
      }
    },
    toDetail(id){
      this.$router.push({
        name: 'CaseDetail',
        query:{
          id: id,
        }
      })
    },
    toDetail2(id){
      this.$router.push({
        name: 'RecommendDetail',
        query:{
          id: id,
        }
      })
    },
    toDetail3(item){
      this.$router.push({
        name: 'Tank',
        query:{
          sort: item.name,
          // logo: item.icon
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.recommend{
  letter-spacing: 1px;
  background: #F3F8FB;
  padding-bottom: 80px;
  header{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 197px;
    background: url(../../assets/img/headDecoration.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    h3{
      line-height: 50px;
      font-weight: 300;
      font-size: 14px;
    }
    i{
      font-size: 23px;
      line-height: 50px;
    }
    .floatWrap{
      position: absolute;
      top: 54px;
      left: 50%;
      margin-left: -150px;
      width: 300px;
      height: 100px;
    }
  }
  .companyWrap{
    padding: 0 14px;
    .decoration{
      width: 100%;
      height: 160px;
      border-radius: 8px;
    }
    .companyList{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 16px;
      li{
        width: 170px;
        height: 97px;
        margin-bottom: 8px;
        background: #FFFFFF;
        text-align: center;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 47px;
        }
        div{
          text-align: left;
          margin-left: 10px;
          width: 79px;
          .englishName{
            font-size: 10px;
            color: #868A8B;
            font-weight: 100;
          }
        }
      }
      li:nth-child(3){
        div{
          margin-left: 8px;
          width: 79px;
          .englishName{
            font-size: 10px;
            color: #868A8B;
            transform: scale(0.8);
            margin-left: -10px;
            line-height: 20px;
          }
        }
      }
      // img:nth-child(1){
      //   width: 32px;
      //   height: 31px;
      // }
      // img:nth-child(2){
      //   width: 40px;
      //   height: 30px;
      // }
      // img:nth-child(3){
      //   width: 34px;
      //   height: 27px;
      // }
      // img:nth-child(4){
      //   width: 33px;
      //   height: 29px;
      // }
      // img:nth-child(5){
      //   width: 34px;
      //   height: 29px;
      // }
      // img:nth-child(6){
      //   width: 31px;
      //   height: 25px;
      // }
      // img:nth-child(7){
      //   width: 31px;
      //   height: 29px;
      // }
      // img:nth-child(8){
      //   width: 29px;
      //   height: 31px;
      // }
    }
    .homeBlock{
      margin-top: 12px;
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #202324;
        h4{
          line-height: 40px;
          font-size: 18px;
        }
        span{
          font-size: 15px;
        }
      }
      .enjoy{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
          width: 164px;
          margin-bottom: 8px;
          img{
            width:100%;
            height: 100px;
            border-radius: 12px;
          }
          p{
            color: #202324;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>