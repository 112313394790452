/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 更新个人信息
 * request: /api/v1/profile
 * method: PUT
 */
export const Update = function(data) {
  let path = '/api/v1/profile';
  return request({
    url: path,
    method: 'PUT',
    data,
  })
}
/**
 * 获取个人信息
 * request: /api/v1/profile
 * method: GET
 * eg.
 */
export const Get = function() {
  let path = '/api/v1/profile';
  return request({
    url: path,
    method: 'GET',
  })
}
/**
 * 按需更新
 * request: /api/v1/profile
 * method: PATCH
 */
export const UpdateFields = function(data) {
  let path = '/api/v1/profile';
  return request({
    url: path,
    method: 'PUT',
    data
  })
}
/**
 * 上传文件
 * request: /api/v1/profile/upload
 * method: POST
 */
export const Upload = function(data) {
  let path = '/api/v1/profile/upload';
  return request({
    url: path,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
/**
 * 使用旧密码修改密码
 * request: /api/v1/profile/change-password
 * method: POST
 */
export const ChangePassword = function(data) {
  let path = '/api/v1/profile/change-password';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 返回下线总数
 * request: /api/v1/profile/referrer-stats
 * method: GET
 * eg.
 */
export const GetReferrerStats = function() {
  let path = '/api/v1/profile/referrer-stats';
  return request({
    url: path,
    method: 'GET',
  })
}
/**
 * 返回我的下线
 * request: /api/v1/profile/sub-agents
 * method: GET
 * @param level - 级别
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetSubAgent = function(queryParams) {
  let path = '/api/v1/profile/sub-agents';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}