<template>
  <div class="home"> 
    <div class="logoWrap">
      <img src="./../../assets/img/mine/logo.png" alt="">
    </div>
    <div class="loginHead">
      <ul>
        <li>
          <img src="@/assets/img/login/icon_item1.png" alt="">
          <div>
            <p class="title">找的快</p>
            <p class="sub">多平台专家快速找</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/login/icon_item2.png" alt="">
          <div>
            <p class="title">方便查</p>
            <p class="sub">各类评审案例都有</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/login/icon_item3.png" alt="">
          <div>
            <p class="title">极速约</p>
            <p class="sub">各类专家评审邀约</p>
          </div>
        </li>
      </ul>
    </div>
    <form class="formWrap" v-if="loginPassword">
      <div class="formItem">
        <input type="text" placeholder="输入手机号" v-model="username">
      </div>
      <div class="formItem">
        <input type="password" placeholder="请输入密码" v-model="password">
      </div>
      <div class="loginBtn">
        <button @click.prevent="login">登录</button>
      </div>
    </form>
    <form class="formWrap" v-else>
      <div class="formItem">
        <input type="text" placeholder="输入手机号" v-model="phoneNumber">
      </div>
      <div class="formItem verificationCode">
        <div class="codeLeft">
          <input type="text" placeholder="请输入验证码" v-model="captcha">
        </div>
        <button @click.prevent="sendCode" class="sendCode" :disabled="isSend">
          <label style="color: #D06767">{{isSend?timeNum:''}}</label>
          {{isSend?'s后重新获取':'获取验证码'}}
        </button>
      </div>
      <div class="loginBtn">
        <button @click.prevent="loginByPhone">登录</button>
      </div>
    </form>
    <div class="protocol">
      <van-checkbox v-model="checked"></van-checkbox>
      <p>
        我已经阅读并同意<label @click="toProtocol">《专家库服务协议》</label>
      </p>
    </div>
    <p @click="tabLogin" v-show="!loginPassword" class="tabLogin">切换到密码登录</p>
    <p @click="tabLogin" v-show="loginPassword" class="tabLogin">切换到手机号登录</p>
  </div>
</template>

<script>
import { MagicAppInfo } from '@/api/Main'
import  { loginByPassword, loginByCode } from '@/api/connect'
import  { Get as GetCaptcha } from '@/api/Captcha'
import  { Get } from '@/api/Profile'
import { Checkbox, Toast } from 'vant'
import { isMobile } from '@/util/validate'
export default {
  name: 'About',
  data(){
    return{
      username: '',
      password: '',
      phoneNumber: '',
      captcha: '',
      loginPassword: true,
      isSend: false,
      timeNum: 60,
      text: '获取验证码',
      timer: null,
      checked: true,
    }
  },
  components: {
    [Checkbox.name]: Checkbox,
    [Toast.name]: Toast
  },
  computed: {
    appInfo(){
      return this.$store.getters.app_info?JSON.parse(this.$store.getters.app_info).protocol:null
    }
  },
  mounted(){
    if(!this.appInfo){
      this.getAppInfo()
    }
  },
  methods: {
    toProtocol(){
      this.$router.push("/proto")
    },
    getAppInfo(){
      MagicAppInfo().then((res) => {
        this.$store.dispatch("order/setAppInfo", JSON.stringify(res))
      }).catch((err) => {
      });
    },
    // 发送手机验证码
    sendCode(){
      if(!this.phoneNumber){
        Toast.fail('请输入手机号');
        return false
      }
      if(!isMobile(this.phoneNumber)){
        Toast.fail('请输入正确的手机号');
        return false
      }
      let params = {
        phoneNumberOrEmail: this.phoneNumber,
        type: 'login',
      }
      GetCaptcha(params).then(() => {
        this.isSend = true
        this.timer = setInterval(()=>{
          this.timeNum-=1
          if(this.timeNum<=0){
            this.timeNum = 60
            this.isSend = false
            clearInterval(this.timer)
          }
        },1000)
      }).catch(err => {
        Toast(err.error.message)
      });
      
    },
    // 切换登录方式
    tabLogin(){
      this.loginPassword = !this.loginPassword
    },
    // 密码登录
    login(){
      if(!this.username){
        Toast.fail('请输入手机号');
        return false
      }
      if(!isMobile(this.username)){
        Toast.fail('请输入正确的手机号');
        return false
      }
      if(!this.password){
        Toast.fail('请输入密码');
        return false
      }
      if(!this.checked){
        Toast.fail('请勾选相关协议');
        return
      }
      loginByPassword(this.username,this.password).then(res => {
        this.$store.dispatch("order/setUserToken", res.access_token)
        this.$store.dispatch("order/setRefreshToken", res.refresh_token)
        Get().then(res =>{
          let data = res
          this.$store.dispatch("order/setUserInfo", JSON.stringify(data))
          this.$router.push('/home')
        }).catch(err=>{
        })
      }).catch(error => {
      });
    },
    // 手机号登录
    loginByPhone(){
      loginByCode(this.phoneNumber,this.captcha).then(res => {
        this.$store.dispatch("order/setUserToken", res.access_token)
        this.$store.dispatch("order/setRefreshToken", res.refresh_token)
        Get().then(res =>{
          let data = res
          this.$store.dispatch("order/setUserInfo", JSON.stringify(data))
          this.$router.push('/home')
        }).catch(err=>{
        })
      }).catch(error => {
      });
    }
  }
}
</script>
<style lang="less" scoped>
.home{
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  letter-spacing: 1px;
  overflow-x: hidden;
  overflow-y: scroll;
  .logoWrap{
    text-align: center;
    padding: 54px 0 36px;
    box-sizing: border-box;
    img{
      width: 83px;
      height: 83px;
    }
  }
  .loginHead{
    ul{
      padding: 0 60px;
      li{
        display: flex;
        margin-bottom: 16px;
        img{
          width: 46px;
          margin-right: 20px;
          height: 45px;
        }
        .title{
          font-size: 21px;
          font-weight: 600;
          color: #2B2B2B;
          line-height: 29px; 
        }
        .sub{
          font-size: 16px;
          font-weight: 300;
          color: #7B7B7B;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .formWrap{
    width: 300px;
    margin: 0 auto;
    .formItem{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      input{
        height: 40px;
        border: 0;
        flex: 1;
        padding-left: 12px;
        border-radius: 4px;
        box-sizing: border-box;
        background: #F5F5F5;
        border-radius: 50px;
      }
    }
    .verificationCode{
      .codeLeft{
        display: flex;
        align-items: center;
        flex: 1;
        label{
          width: 60px !important;
        }
        input{
          width: 120px;
        }
      }
      .sendCode{
        padding: 4px 10px;
        background: rgba(0, 0, 0, 0);
        border-radius: 8px;
        min-width: 110px;
        height: 40px;
        font-size: 14px;
        border: 0;
        box-sizing: border-box;
      }
    }
    .loginBtn{
      text-align: center;
      button{
        width: 277px;
        height: 48px;
        background: #056DF6;
        border-radius: 24px;
        border: 0;
        font-size: 21px;
        color: #fff;
      }
    }
  }
  .protocol{
    display: flex;
    justify-content: center;
    margin-top: 9px;
    p{
      color: #7B7B7B;
      font-size: 10px;
      display: flex;
      align-items: center;
      margin-left: 7px;
      label{
        color: #056DF6;
      }
    }
  }
  .tabLogin{
    color: #7B7B7B;
    font-size: 16px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 40px;
  }
}
</style>