import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/Home/Home.vue'
import Case from '../views/Case/Case.vue'
import Tank from '../views/Tank/Tank.vue'
import Recommend from '../views/Recommend/Recommend.vue'
import Mine from '../views/Mine/Mine.vue'
import ChangePassword from '../views/Mine/ChangePassword.vue'
import MyOrder from '../views/OrderPage/MyOrder.vue'
import OrderDetail from '../views/OrderPage/OrderDetail.vue'
import Login from '../views/Login/Login.vue'
import Register from '../views/Register/Register.vue'
import HomeDetail from '../views/Home/HomeDetail.vue'
import RecommendDetail from '../views/Recommend/RecommendDetail.vue'
import TankDetail from '../views/Tank/TankDetail.vue'
import Distributions from '../views/Distributions/Distributions.vue'
import Invite from '../views/Invite/Invite.vue'
import CollectEnterprise from '../views/Collect/Enterprise.vue'
import CollectExpert from '../views/Collect/Expert.vue'
import BusinessPart from '../views/Mine/BusinessPart.vue'
import Feedback from '../views/Mine/Feedback.vue'
import AboutUs from '../views/Mine/AboutUs.vue'
import Search from '../views/Search/Search.vue'
import ReviewMeet from '../views/ReviewMeeting/ReviewMeeting.vue'
import EditInfo from '../views/Mine/EditInfo.vue'
import WithDraw from '../views/Mine/Withdraw.vue'
import CaseDetail from '../views/Case/CaseDetail.vue'
import AcquisitionLetter from '../views/AcquisitionLetter/AcquisitionLetter.vue'
import PayOrder from '../views/PayOrder/PayOrder.vue'
import PaySuccess from '../views/PayOrder/PaySuccess.vue'
import Proto from '../views/Protocol/index.vue'
Vue.use(VueRouter);
// import utils from './../utils' 
const routes = [
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'Home',
    component: Home
  }, {
    path: '/case',
    name: 'Case',
    component: Case
  }, {
    path: '/mine',
    name: 'Mine',
    component: Mine
  }, {
    path: '/tank',
    name: 'Tank',
    component: Tank
  }, {
    path: '/tankDetail',
    name: 'TankDetail',
    component: TankDetail
  }, {
    path: '/recommend',
    name: 'Recommend',
    component: Recommend
  }, {
    path: '/myOrder',
    name: 'MyOrder',
    component: MyOrder
  }, {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: OrderDetail
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/homeDetail',
    name: 'HomeDetail',
    component: HomeDetail
  }, {
    path: '/recommendDetail',
    name: 'RecommendDetail',
    component: RecommendDetail
  }, {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword
  }, {
    path: '/register',
    name: 'Register',
    component: Register
  }, {
    path: '/distribution',
    name: 'Distributions',
    component: Distributions
  }, {
    path: '/invite',
    name: 'Invite',
    component: Invite
  }, {
    path: '/collectExpert',
    name: 'CollectExpert',
    component: CollectExpert
  }, {
    path: '/collectEnterprise',
    name: 'CollectEnterprise',
    component: CollectEnterprise
  }, {
    path: '/businessPart',
    name: 'BusinessPart',
    component: BusinessPart
  }, {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  }, {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs
  }, {
    path: '/search',
    name: 'Search',
    component: Search
  }, {
    path: '/reviewMeet',
    name: 'ReviewMeet',
    component: ReviewMeet
  }, {
    path: '/editInfo',
    name: 'EditInfo',
    component: EditInfo
  }, {
    path: '/withDraw',
    name: 'WithDraw',
    component: WithDraw
  }, {
    path: '/caseDetail',
    name: 'CaseDetail',
    component: CaseDetail
  }, {
    path: '/acquisitionLetter',
    name: 'AcquisitionLetter',
    component: AcquisitionLetter
  }, {
    path: '/payOrder',
    name: 'PayOrder',
    component: PayOrder
  }, {
    path: '/paySuccess',
    name: 'PaySuccess',
    component: PaySuccess
  }, {
    path: '/proto',
    name: 'Proto',
    component: Proto
  }
]
const router = new VueRouter({
  routes
});
export default router
