<!--
 * @Author: your name
 * @Date: 2021-06-26 22:39:39
 * @LastEditTime: 2021-08-15 02:26:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \testc:\Users\admin\Desktop\expert-tank\src\views\Case\Case.vue
-->
<template>
  <div class="case"> 
    <header>
      <h3>评审案例</h3>
      <van-icon name="search" @click="toSearch"/>
      <div class="floatWrap" @click="toAcquisitionLetter"></div>
    </header>
    <div class="companyWrap">
      <van-tabs v-model="active" swipe-threshold="2" @click="handleTab">
        <van-tab title="全部">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="暂无数据"
            @load="getList"
            class="companyList"
          >
            <li v-for="(item,index) in companyList" :key="index" @click="toDetail(item.id)">
             <img :src="item.cover" alt=""> 
             <p>{{item.name}}</p>
            </li>
          </van-list>
        </van-tab>
        <van-tab :title="item.name" v-for="(item,index) in tabTypeList" :key="index">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="暂无数据"
            @load="getList"
            class="companyList"
          >
            <li v-for="(item,index) in companyList" :key="index" @click="toDetail(item.id)">
             <img :src="item.cover" alt=""> 
             <p>{{item.name}}</p>
            </li>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { Tab, Tabs, Icon, List } from 'vant';
import { GetList, GetTypes } from '@/api/Review'
export default {
  name: 'Case',
  data(){
    return{
      active: 0,
      companyList: [],
      tabTypeList: [],
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 20,
      params: {
        TypeId: '',
        SkipCount: '',
        MaxResultCount: 20,
        Sorting: 'LastModificationTime desc'
      }
    }
  },
  components: {
    TabBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List 
  },
  mounted(){
    this.getType()
  },
  methods: {
    // 跳转到征集函
    toAcquisitionLetter(){
      this.$router.push("/acquisitionLetter")
    },
    // 切换tab
    handleTab(index){
      if(index!==0){
        let id = this.tabTypeList[index-1].id
        this.params.TypeId = id
      }else{
        this.params.TypeId = '' 
      }
      this.currentPage = 0
      this.getList()
    },
    getType(){
      GetTypes().then(res=>{
        this.tabTypeList = res
      }).catch(err=>{
      })
    },
    getList(){
      this.currentPage += 1
      this.params.SkipCount = (this.currentPage - 1) * this.pageSize
      GetList(this.params).then((res)=>{
        this.loading = true
        this.finished = true
        this.companyList = res.items
      }).catch(err=>{
      })
    },
    // 跳转到搜索页面
    toSearch(){
      this.$router.push("/search")
    },
    toDetail(id){
      this.$router.push({
        name: 'CaseDetail',
        query:{
          id: id,
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.case{
  padding-bottom: 80px;
  header{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 197px;
    color: #fff;
    background: url(../../assets/img/headDecoration.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    h3{
      line-height: 50px;
      height: 50px;
      font-weight: 300;
      font-size: 14px;
    }
    i{
      font-size: 23px;
      line-height: 50px;
      height: 50px;
    }
    .floatWrap{
      position: absolute;
      top: 54px;
      left: 50%;
      margin-left: -150px;
      width: 300px;
      height: 100px;
    }
  }
  .companyWrap{
    padding: 0 14px;
    .decoration{
      width: 100%;
      height: 160px;
      border-radius: 8px;
    }
    .van-tabs{
      
      /deep/.van-tabs__nav{
        justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;
      }
      /deep/ .van-tab{
        padding: 0 12px;
      }
      /deep/ .van-tab--active{
        .van-tab__text{
          font-size: 18px;
          font-weight: 600;
          color: #202324;
          text-overflow: unset;
        }
      }
      /deep/ .van-tabs__line{
        background: #C4DBFE;
        width: 38px;
        height: 2px;
        border-radius: 5px;
        bottom: 20px;
      }
      .companyList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 12px;
        li{
          width: 169px;
          margin-bottom: 15px;
          img{
            width:100%;
            height: 95px;
            border-radius: 14px;
            margin-bottom: 4px;
          }
          p{
            font-size: 13px;
            color: #202324;
            letter-spacing: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        /deep/.van-list__finished-text{
          width: 100%;
        }
        /deep/.van-list__loading{
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>