/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取佣金记录
 * request: /api/v1/commission
 * method: GET
 * @param filter - 
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/commission';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 获取佣金详情
 * request: /api/v1/commission/{id}
 * method: GET
 * @param id - The identifier.
 * eg.
 */
export const Get = function(id) {
  let path = '/api/v1/commission/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'GET',
  })
}