/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取专家详情
 * request: /api/v1/experts/{id}
 * method: GET
 * @param id - The identifier.
 * eg.
 */
export const Get = function(id) {
  let path = '/api/v1/experts/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'GET',
  })
}
/**
 * 获取专家列表
 * request: /api/v1/experts
 * method: GET
 * @param filter - 关键字
 * @param subTypeId - 子类Id
 * @param typeId - 大类Id
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/experts';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 获取推荐专家
 * request: /api/v1/experts/recommend
 * method: GET
 * @param subTypeId - 子类Id
 * @param typeId - 大类Id
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetRecommendList = function(queryParams) {
  let path = '/api/v1/experts/recommend';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 获取专家类别
 * request: /api/v1/experts/types
 * method: GET
 * eg.
 */
export const GetTypes = function() {
  let path = '/api/v1/experts/types';
  return request({
    url: path,
    method: 'GET',
  })
}

export const GetRecruitInfo = function() {
  let path = 'api/v1/home/recruit-info';
  return request({
    url: path,
    method: 'GET',
  })
}