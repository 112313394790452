<template>
  <div class="recommend"> 
    <header>
      <div class="title">
        <van-icon name="arrow-left" @click="handleBack"/>
        <h3>评审案例</h3>
      </div>
      <div class="contentText">
        <p class="chText">{{info.name}}</p>
      </div>
    </header>
    <div class="companyWrap">
      <ul class="introduce">
        <li>
          <h4>案例详情</h4>
          <p v-html="info.desc"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Get } from '@/api/Review'
import { Button, Icon, NavBar } from 'vant';
export default {
  name: 'RecommendDetail',
  data(){
    return{
      // active: 2,
      tankId: '',
      info: {}
    }
  },
  created(){
    this.tankId = this.$route.query.id;
    this.getDetail()
  },
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  methods: {
    getDetail(){
      Get(this.tankId).then(res=>{
        this.info = res
      }).catch(err=>{
      })
    },
    handleBack(){
      this.$router.back(-1)
    }
  }
}
</script>
<style lang="less" scoped>

.recommend{
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;
  header{
    padding: 0 20px;
    height: 197px;
    background: url(../../assets/img/exampleBg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .title{
      h3{
        line-height: 50px;
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
      i{
        font-size: 16px;
        line-height: 50px;
        position: absolute;
        left: 16px;
        color: #fff;
      }
    }
    .contentText{
      color: #fff;
      padding-left: 44px;
      padding-top: 40px;
      box-sizing: border-box;
      .chText{
        font-size: 21px;
        margin-bottom: 4px;
      }
      .enText{
        font-weight: 100;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 1px;
        transform: scale(.8);
        margin-left: -32px;
      }
    }
  }
  .companyWrap{
    background: #fff;
    .introduce{
      background: #F3F8FB;
      li{
        margin-bottom: 13px;
        background: #fff;
        padding: 18px 32px;
      }
      h4{
        line-height: 40px;
        font-weight: normal;
        font-size: 15px;
        color: #868A8B;
        font-family: "\5FAE\8F6F\96C5\9ED1",sans-serif;
      }
      p{
        letter-spacing: .4px;
        line-height: 22px;
        font-size: 12px;
        color: #202324;
      }
    }
  }
}
</style>
<style>
.ql-align-center img{
  width: auto;
  margin: 10px auto;
  max-width: 100%;
  height: auto;
  display: block;
}
</style>