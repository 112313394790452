import axios from 'axios';
import { serialize } from '@/util/util';
import { Toast } from 'vant';
import  Qs from 'qs'
// import { Notify } from 'vant';
axios.defaults.paramsSerializer = params => Qs.stringify(params, { arrayFormat: 'repeat' })
axios.defaults.timeout = 120000;
// 返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
axios.defaults.withCredentials = false;
// request拦截器
axios.interceptors.request.use(
  config => {
    const meta = config.meta || {};
    config.baseURL = "https://api.pszjk.com"; // url = base url + request url
    let token = sessionStorage.getItem('user_token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('user_token');
    }
    // headers中配置text请求
    if (config.text === true) {
      config.headers['Content-Type'] = 'text/plain';
    }
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && meta.isSerialize === true) {
      config.data = serialize(config.data);
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.config.responseType == 'blob') {
      if (response.status == 200) {
        return response;
      }
      return Promise.reject('error');
    }
    const { status, data } = response;
    if (status === 200 || status === 204 || status === 201) {
      return response.data;
    }
    let message = data.message || '未知错误';
    const { error, error_description } = data;
    if (error_description) {
      message = error_description;
    }
    if (typeof error === 'object') {
      message = error.message || '未知错误';
    }
    if (status === 404) {
      message = '接口资源不存在';
    }
    if (status === 401) {
      sessionStorage.clear();
    }
    // 如果请求为非200否者默认统一处理
    Toast.fail(message);
    return Promise.reject(new Error(message));
  },
  error => {
    return Promise.reject(error);
  }
);
export default axios;
