/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取重置密码的token
 * request: /api/v1/account/generate-password-reset-token
 * method: POST
 */
export const GeneratePasswordResetToken = function(data) {
  let path = '/api/v1/account/generate-password-reset-token';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 重置密码
 * request: /api/v1/account/reset-password
 * method: POST
 */
export const ResetPassword = function(data) {
  let path = '/api/v1/account/reset-password';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 注册
 * request: /api/v1/account/register
 * method: POST
 */
export const Register = function(data) {
  let path = '/api/v1/account/register';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}