<template>
  <div class="mineWrap">
    <div class="content">
      <article>
        <img :src="user_info.avatar" alt="" v-show="user_info.avatar">
        <img src="./../../assets/img/avatar.jpg" alt="" v-show="!user_info.avatar">
        <div>
          <div class="userRole">
            <span>{{user_info.nickName?user_info.nickName:'用户'}}</span><label v-show="user_info.company!==null">（{{user_info.company}}）</label>
          </div>
          <p>编辑资料<van-icon name="edit" @click="toEdit" style="font-size: 20px;margin-left: 4px;color: #868A8B;"/></p>
        </div>
      </article>
      <ul class="ulList1">
        <li v-for="(item,index) in mineList" :key="index+'s'">
          <div @click="toPage(item.url)"><img :src="item.imgUrl" alt="" :style="item.styles"></div>
          <p>{{item.title}}</p>
        </li>
      </ul>
      <ul class="ulList2">
        <li v-for="(item,index) in mineList2" :key="index+'s'" @click="toPage(item.url)">
          <div>
            <img :src="item.imgUrl" alt="">
          </div>
          <p>{{item.title}}</p>
        </li>
      </ul>
      <!-- 轮播图 -->
      <!-- <van-swipe class="my-swipe advertiseWrap" :autoplay="3000" indicator-color="white" :show-indicators="false">
        <van-swipe-item>
          <img src="../../assets/img/mine/banner1.png" alt=""> 
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/img/mine/banner2.png" alt=""> 
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/img/mine/banner3.png" alt=""> 
        </van-swipe-item>
      </van-swipe> -->
      <ul class="ulList3">
        <li v-for="(item,index) in mineList3" :key="index+'s'" @click="toPage(item.url)">
          <div>
            <img :src="item.imgUrl" alt="" :style="item.styles">
            {{item.title}}
          </div>
          <van-icon name="arrow"/>
        </li>
        <li @click="logout">
          <div>
            <img src="@/assets/img/mine/icon_logout.png" alt="" style="width:19px;height:19px;">
            退出登陆
          </div>
        </li>
      </ul>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { Icon, Swipe, SwipeItem, Dialog } from 'vant';
import { MagicAppInfo } from '@/api/Main'
import { Get } from '@/api/Profile'
export default {
  name: 'Mine',
  data(){
    return{
      mineList: [
        {
          imgUrl: require('@/assets/img/mine/order.png'),
          title: '我的订单',
          url: '/myOrder',
          styles: 'width:26px;height:31px;'
        }, {
          imgUrl: require('@/assets/img/mine/center.png'),
          title: '分销中心',
          url: '/distribution',
          styles: 'width:34px;height:27px;'
        }, {
          imgUrl: require('@/assets/img/mine/invite.png'),
          title: '邀请好友',
          url: '/invite',
          styles: 'width:26px;height:29px;'
        }
      ],
      mineList2: [
        {
          imgUrl: require('@/assets/img/mine/collect.png'),
          url: '/collectExpert',
          title: '收藏专家'
        }, {
          imgUrl: require('@/assets/img/mine/watch.png'),
          url: '/collectEnterprise',
          title: '关注企业'
        }
      ],
      mineList3: [
        {
          imgUrl: require('@/assets/img/mine/part.png'),
          title: '商务合作',
          url: '/businessPart',
          styles: 'width:19px;height:17px;'
        }, {
          imgUrl: require('@/assets/img/mine/opinion.png'),
          title: '意见反馈',
          url: '/feedback',
          styles: 'width:17px;height:20px;'
        }, {
          imgUrl: require('@/assets/img/mine/about.png'),
          title: '关于我们',
          url: '/aboutUs',
          styles: 'width:19px;height:19px;'
        }
      ]
    }
  },
  components: {
    TabBar,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Dialog.name]: Dialog,
  },
  computed: {
    user_info(){
      return JSON.parse(this.$store.state.order.user_info)
    },
    app_info(){
      return this.$store.state.order.app_info||''
    }
  },
  mounted(){
    this.getAppInfo()
    this.getInfo()
  },
  methods: {
    logout(){
      Dialog.confirm({
        title: '退出登录？',
        beforeClose: this.beforeClose,
      });
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        done();
        sessionStorage.clear()
        this.$router.push("/login")
      } else {
        done();
      }
    },
    getInfo(){
      Get().then((res) => {
        this.$store.dispatch("order/setUserInfo", JSON.stringify(res))
      }).catch((err) => {
      });
    },
    getAppInfo(){
      MagicAppInfo().then((res) => {
        this.$store.dispatch("order/setAppInfo", JSON.stringify(res))
      }).catch((err) => {
      });
    },
    // 点击跳转编辑个人资料页面
    toEdit(){
      this.$router.push("/editInfo")
    },
    // 页面跳转
    toPage(url){
      this.$router.push(url)
    },
    // 跳转更换密码页面
    changePassword(){
      this.$router.push("/changePassword")
    }
  }
}
</script>
<style lang="less" scoped>
.mineWrap{
  height: 100vh;
  background: #F3F8FB;
  padding-bottom: 140px;
  .content{
    overflow-y: scroll;
    article{
      display: flex;
      align-items: flex-end;
      padding: 0 16px 20px;
      box-sizing: border-box;
      background: #fff;
      height: 147px;
      letter-spacing: 1px;
      background: linear-gradient(180deg, #78B9F3 0%, #B6E8F8 40%, #FFFFFF 100%);
      img{
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin-right: 12px;
      }
      div{
        flex: 1;
        .userRole{
          font-size: 10px;
          color: #494949;
          span{
            font-size: 18px;
            font-weight: 600;
          }
        }
        p{
          color: #868A8B;
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
    .ulList1{
      display: flex;
      background: #fff;
      padding: 24px 0 28px;
      li{
        flex: 1;
        text-align: center;
        div{
          width: 40px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          img{
            width: 30px;
            height: 30px;
          }
        }
        p{
          color: #202324;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
    .ulList2{
      display: flex;
      padding: 13px 15px;
      justify-content: space-between;
      li{
        text-align: center;
        background: #fff;
        width: 166px;
        padding: 46px 0;
        box-sizing: border-box;
        border-radius: 21px;
        div{
          height: 50px;
          width: 50px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 41px;
          }
        }
        p{
          color: #494949;
          font-size: 15px;
          line-height: 21px;
          margin-top: 8px;
          font-weight: 500;
        }
      }
    }
    .advertiseWrap{
      height: 53px;
      padding: 0 15px;
      box-sizing: border-box;
      img{
        height: 53px;
        border-radius: 21px;
      }
    }
    .ulList3{
      margin: 10px 15px 10px;
      background: #fff;
      border-radius: 21px;
      padding: 12px 0;
      li{
        height: 50px;
        font-size: 14px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div{
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #494949;
          img{
            margin-right: 16px;
          }
        }
        i{
          color: #979797;
          font-size: 6px !important;
        }
      }
    }
    .logout{
      width: 200px;
      margin: 20px auto;
      height: 40px;
      border-radius: 8px;
      background: rgb(247, 63, 63);
      color: #fff;
      text-align: center;
      line-height: 40px;
    }
  }
}
</style>