import { render, staticRenderFns } from "./EditInfo.vue?vue&type=template&id=58a36f9c&scoped=true&"
import script from "./EditInfo.vue?vue&type=script&lang=js&"
export * from "./EditInfo.vue?vue&type=script&lang=js&"
import style0 from "./EditInfo.vue?vue&type=style&index=0&id=58a36f9c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a36f9c",
  null
  
)

export default component.exports