<!--
 * @Author: your name
 * @Date: 2021-07-16 23:39:17
 * @LastEditTime: 2021-07-18 01:56:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \testc:\Users\admin\Desktop\expert-tank\src\views\Mine\Feedback.vue
-->
<template>
  <div class="feedback">
    <van-nav-bar
      title="意见反馈"
      left-arrow
      @click-left="onClickLeft">
      <!-- <template #right>
        <img src="./../../assets/img/mine/icon_help.png"/>
        <span style="color: #8C8C8C;margin-left: 4px;">电话求助</span> 
      </template> -->
    </van-nav-bar>
    <div class="feedbackContent">
      <!-- 问题列表 -->
      <!-- <div class="questionList">
        <p class="title">遇到了什么问题？</p>
        <ul>
          <li v-for="(item, index) in list" :key="index" :class="item.active?'active':''" @click="handleTab(index)">
            <img :src="item.imgUrlActive" alt="" v-if="item.active">
            <img :src="item.imgUrl" alt="" v-else>
            <p>{{item.title}}</p>
          </li>
        </ul>
      </div> -->
      <!-- 提问 -->
      <div class="quizWrap">
        <p class="title">请描述具体问题：</p>
        <div class="example">
          <span  @click="handleTabQuestion(index)" v-for="(item,index) in questionList" :key="index" :class="item.active?'active':''">{{item.title}}</span>
        </div>
        <!-- 问题输入文本框 -->
        <van-field
          v-model="message"
          rows="3"
          style="border: 1px solid #E2E2E2;margin-top:12px;border-radius: 9px;"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请描述您使用专家库遇到的问题和意见若功能或操作异常，烦请截图上传更快解决问题哦！"
          show-word-limit
        />
        <!-- 上传图片部分 -->
        <!-- <div class="uploadWrap">
          <p class="title">添加图片（选填）</p>
          <p class="subTitle">上传截图能更快解决问题哦</p>
          <van-uploader :after-read="afterRead" v-model="fileList" :max-count="6" :max-size="500 * 1024" @oversize="onOversize" />
        </div> -->
      </div>
      <!-- 提交按钮 -->
      <div class="submitBtn" @click="handleSubmit">提交</div>
    </div>
  </div>
</template>

<script>
  import { Create } from '@/api/Advice';
  import { Icon, NavBar, Field, Uploader, Toast} from 'vant';
  export default {
    data(){
      return {
        message: '',
        list: [{
          title: '找专家',
          active: true,
          imgUrl: require('./../../assets/img/mine/icon_expert.png'),
          imgUrlActive: require('./../../assets/img/mine/icon_expert_active.png')
        }, {
          title: '找公司',
          active: false,
          imgUrl: require('./../../assets/img/mine/icon_company.png'),
          imgUrlActive: require('./../../assets/img/mine/icon_company_active.png')
        }, {
          title: '做评审',
          active: false,
          imgUrl: require('./../../assets/img/mine/icon_review.png'),
          imgUrlActive: require('./../../assets/img/mine/icon_review_active.png')
        }, {
          title: '其他的',
          active: false,
          imgUrl: require('./../../assets/img/mine/icon_other.png'),
          imgUrlActive: require('./../../assets/img/mine/icon_other_active.png')
        }],
        questionList: [{
          title: '找不到对应专家',
          type: 0,
          active: true,
        }, {
          title: '找不到对应公司',
          type: 1,
          active: false,
        }, {
          title: '对订单有疑问',
          type: 2,
          active: false,
        }, {
          title: '对专家信息有疑问',
          type: 3,
          active: false,
        }, {
          title: '投诉和建议',
          type: 4,
          active: false,
        }, {
          title: '其他问题',
          type: 5,
          active: false,
        }],
        fileList: [
          { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }
        ],
        type: '找不到对应专家'
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Toast.name]: Toast
    },
    methods: {
      // 上传按钮
      handleSubmit(){
        if(this.type===''){
          Toast("请选择问题类型")
          return 
        }
        if(this.message===''){
          Toast("请填写详情的问题")
          return 
        }
        let params = {
          type: this.type,
          content: this.message
        }
        Create(params).then(res=>{
          Toast.success('意见反馈成功');
        }).catch(err=>{
        })
      },
      // 限制上传图片大小
      onOversize(file) {
        Toast('文件大小不能超过 500kb');
      },
      // 上传图片
      afterRead(file) {
        // 此时可以自行将文件上传至服务器
      },
      // 切换遇到的问题
      handleTab(index){
        this.list.map((item)=>{
          item.active = false
        })
        this.list[index].active = true
      },
      // 切换具体问题
      handleTabQuestion(index){
        this.questionList.map((item)=>{
          item.active = false
        })
        this.questionList[index].active = true
        this.type = this.questionList[index].title
      },
      // 返回上级页面
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.feedback{
  background: #f3f8fb;
  width: 100vw;
  min-height: 100vh;
  letter-spacing: 1px;
  padding-bottom: 40px;
  .feedbackContent{
    padding: 0 20px;
    .questionList{
      padding: 8px 17px 32px;
      background: #FFFFFF;
      border-radius: 14px;
      margin-top: 12px;
      box-sizing: border-box;
      .title{
        font-size: 15px;
        font-weight: 600;
        color: #5A5A5A;
        line-height: 21px;
        letter-spacing: 1px;
        margin-bottom: 12px;
      }
      ul{
        display: flex;
        justify-content: space-between;
        li{
          width: 70px;
          height: 70px;
          text-align: center;
          background: #F3F8FB;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-radius: 9px;
          img{
            width: auto;
            height: auto;
          }
          p{
            height: 21px;
            font-size: 15px;
            font-weight: 600;
            color: #9F9F9F;
            line-height: 21px;
          }
        }
        .active{
          background: #C8E8FC;
          p{
            color: #5A5A5A;
          }
        }
      }
    }
    .quizWrap{
      padding: 20px 20px 32px;
      background: #FFFFFF;
      margin-top: 20px;
      border-radius: 14px;
      box-sizing: border-box;
      .title{
        font-size: 15px;
        font-weight: 600;
        color: #5A5A5A;
        line-height: 21px;
        letter-spacing: 1px;
        margin-bottom: 12px;
      }
      .example{
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        span{
          padding: 5px 8px;
          font-size: 10px;
          font-weight: 600;
          color: #A7A8A8;
          line-height: 15px;
          background: #F3F8FB;
          border-radius: 20px;
          transform: scale(.9);
          margin-bottom: 4px;
        }
        .active{
          background: #C4DBFE;
          color: #3070E0;
        }
      }
      .uploadWrap{
        .title{
          margin-top: 20px;
          margin-bottom: 4px;
        }
        .subTitle{
          font-size: 13px;
          font-weight: 300;
          color: #8C8C8C;
          line-height: 18px;
          margin-bottom: 8px;
        }
        /deep/ .van-uploader__upload{
          .van-icon{
            font-size: 30px !important;
          }
        }
      }
    }
    .submitBtn{
      width: 322px;
      height: 49px;
      background: #FAF4DC;
      border-radius: 4px;
      margin: 18px auto;
      font-size: 17px;
      font-weight: 500;
      color: #F2916F;
      line-height: 49px;
      text-align: center;
    }
  }
  
}

</style>