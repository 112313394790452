<!--
 * @Author: your name
 * @Date: 2021-06-26 22:39:39
 * @LastEditTime: 2021-07-04 11:13:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \testc:\Users\admin\Desktop\expert-tank\src\components\TabBar\index.vue
-->
<template>
  <div class="tabBarWrap">
    <van-tabbar :route="true" active-color="#1565D0" :active="active" @change="onChange">
      <van-tabbar-item :to="item.path" replace v-for="(item,index) in iconList" :key="item+index">
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.normal" />
        </template>
        {{item.name}}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  import { Tabbar, TabbarItem } from 'vant';
  export default {
    name: 'tabBar',
    data(){
      return {
        // icon: {
        //   normal: 'https://img.yzcdn.cn/vant/user-inactive.png',
        //   active: 'https://img.yzcdn.cn/vant/user-active.png',
        // },
        active: 0,
        iconList: [
          {
            icon: {
              normal: require('@/assets/img/tabBar/home.png'),
              active: require('@/assets/img/tabBar/home_active.png'),
            },
            name: '首页',
            path: '/home'
          },
          {
            icon: {
              normal: require('@/assets/img/tabBar/tank.png'),
              active: require('@/assets/img/tabBar/tank_active.png'),
            },
            name: '专家库',
            path: '/tank'
          },
          {
            icon: {
              normal: require('@/assets/img/tabBar/case.png'),
              active: require('@/assets/img/tabBar/case_active.png'),
            },
            name: '评审案例',
            path: '/case'
          },
          {
            icon: {
              normal: require('@/assets/img/tabBar/recommend.png'),
              active: require('@/assets/img/tabBar/recommend_active.png'),
            },
            name: '名企推荐',
            path: '/recommend'
          },
          {
            icon: {
              normal: require('@/assets/img/tabBar/mine.png'),
              active: require('@/assets/img/tabBar/mine_active.png'),
            },
            name: '我的',
            path: '/mine'
          }
        ]
      }
    },
    components: {
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem
    },
    mounted(){
      
    },
    methods: {
      onChange(event){
        this.active = event.detail
      }
    }
  }
</script>

<style lang="less" scoped>
.tabBarWrap{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /deep/ .van-tabbar-item__text{
    transform: scale(.8);
  }
}
</style>