<!--
 * @Author: your name
 * @Date: 2021-07-16 23:39:17
 * @LastEditTime: 2021-07-18 00:58:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \testc:\Users\admin\Desktop\expert-tank\src\views\Mine\BusinessPart.vue
-->
<template>
  <div class="business">
    <van-nav-bar
      title="商务合作"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="aboutContent">
      <div class="infoIntro">
        <span>hi~</span>
        <img :src="user_info.avatar" alt="">
        <span>你好，{{user_info.name}}~</span>
      </div>
      <div class="item">
        <div class="contact">
          <p>非常感谢您使用专家库，如果您有商务合作需求，可以与我们联系。</p>
          <h4>{{app_info.contactPhone}}</h4>
        </div>
        <div class="blurInfo"></div>
        <div class="call"><a style="color: #F2916F" :href="'tel:'+app_info.contactPhone">立刻电话联系</a></div>
      </div>
      <div class="item">
        <div class="contact">
          <p>您也可以给我们发邮件，将会在2个工作日内回复您。</p>
          <h4 style="font-size: 18px;">E-mail:{{app_info.contactEmail}}</h4>
        </div>
        <div class="blurInfo"></div>
        <div class="call"><a style="color: #F2916F;" :href="'mailto:'+app_info.contactEmail">发一封电子邮件</a></div>
      </div>
      <div class="questionWrap">
        <p>你可能有以下关于合作的疑问</p>
        <div class="example">
          <span>合作流程是什么？</span><span>第一次合作注意什么问题？</span>
          <span>合作的条件有哪些？</span><span class="orange">怎么收费的呢？</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Icon, NavBar } from 'vant';
  export default {
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
    },
    computed: {
      user_info(){
        return JSON.parse(this.$store.state.order.user_info)
      },
      app_info(){
        return JSON.parse(this.$store.state.order.app_info)
      }
    },
    methods: {
      // 返回上级页面
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.business{
  background: #f3f8fb;
  width: 100vw;
  min-height: 100vh;
  letter-spacing: 1px;
  height: 859px;
  background: linear-gradient(180deg, rgba(43, 132, 247, .8) 0%, rgba(243, 248, 251, .8) 100%, rgba(243, 248, 251, .8) 100%);
  opacity: 0.79;
  /deep/.van-nav-bar {
    background: rgba(0, 0, 0, 0);
    .van-nav-bar__title{
      color: #FFF;
    }
    .van-icon{
      color: #fff;
    }
  }
  /deep/.van-hairline--bottom::after{
    border: 0;
  }
  .aboutContent{
    .infoIntro{
      display: flex;
      font-size: 15px;
      color: #494949;
      font-weight: 600;
      background: url('./../../assets/img/mine/kefu.png') no-repeat 90% center;
      background-size: 60px 60px;
      padding: 18px 40px;
      align-items: center;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        margin: 0 12px;
      }
    }
    .item{
      padding: 0 20px;
      position: relative;
      .blurInfo{
        background: #E4E4E4;
        box-shadow: 0px 5px 4px 0px #E4E4E4;
        border-radius: 14px;
        border: 1px solid #F5F5F5;
        filter: blur(10px);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100vw;
        box-sizing: border-box;
        height: 153px;
        width: 334px;
        margin: 0 auto;
        z-index: -1;
      }
      .contact{
        height: 153px;
        background: #FFFFFF;
        border-radius: 14px;
        padding: 20px 16px;
        box-sizing: border-box;
        h4{
          text-align: center;
          font-size: 31px;
          font-weight: 600;
          color: #5A5A5A;
          line-height: 44px;
          letter-spacing: 2px;
          margin-top: 27px;
        }
        p{
          font-size: 15px;
          font-weight: 500;
          color: #5A5A5A;
          line-height: 21px;
          letter-spacing: 1px;
        }
      }
      .call{
        width: 322px;
        height: 49px;
        background: #FAF4DC;
        border-radius: 4px;
        margin: 18px auto;
        font-size: 17px;
        font-weight: 500;
        color: #F2916F;
        line-height: 49px;
        text-align: center;
      }
    }
    .questionWrap{
      padding: 0 20px;
      p{
        font-size: 15px;
        font-weight: 500;
        color: #5A5A5A;
        line-height: 21px;
      }
      .example{
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        span{
          padding: 5px 8px;
          font-size: 10px;
          font-weight: 600;
          color: #fff;
          line-height: 15px;
          background: #3070E0;
          border-radius: 20px;
          transform: scale(.9);
          margin-bottom: 4px;
        }
        .orange{
          background: #F9E9DF;
          color: #F78334;
        }
      }
    }
  }
}

</style>