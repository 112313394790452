<template>
  <div class="tank">
    <van-nav-bar
      title=""
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="contentWrap">
      <div class="blurInfo"></div>
      <div class="info">
        <img src="./../../assets/img/icon/pay_success.png" alt="">
        <p class="state">成功支付</p>
        <!-- <p class="money">2000.00元</p> -->
        <p class="note">请保持电话畅通</p>
        <p class="note">24小时内客服联系您的手机号</p>
      </div>
      <van-popup round v-model="show" class="popUpPay">
        <h3 class="title">请确认微信支付是否已完成</h3>
        <p class="item red" @click="handleTestPay">已支付</p>
        <p class="item">支付遇到问题，重新支付</p>
      </van-popup>
    </div>
    <!-- <div class="payBtn">3s后返回订单列表</div> -->
  </div>
</template>
<script>
import { Icon, NavBar, Popup, Toast  } from 'vant';
import { Get } from '@/api/Order' 

export default {
  name: 'Mine',
  data(){
    return{
      collectState: false,
      show: true,
      orderId: '',
      collectUrl: require('./../../assets/img/icon/icon_collect.png'),
      collectTrueUrl: require('./../../assets/img/icon/icon_collectTrue.png')
    }
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Popup.name]: Popup 
  },
  mounted(){
    this.orderId = this.$route.query.orderId
  },
  methods: {
    // 点击验证是否支付成功
    handleTestPay(){
      Get(this.orderId).then((res) => {
        if(res.status!==3){
          Toast('支付失败')
        }else{
          Toast('支付成功')
          this.show = false
        }
      }).catch((err) => {
      });
    },
    onClickLeft(){
      this.$router.back(-1)
    },
    toMeet(){
      this.$router.push('/reviewMeet')
    }
  }
}
</script>
<style lang="less" scoped>
.tank{
  background: #F3F8FB;
  letter-spacing: 1px;
  padding-bottom: 20px;
  min-height: 100vh;
  .contentWrap{
    padding: 0 13px 30px;
    position: relative;
    margin-top: 20px;
    .blurInfo{
      background: #E4E4E4;
      box-shadow: 0px 5px 4px 0px #E4E4E4;
      border-radius: 14px;
      border: 1px solid #F5F5F5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: -10px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 392px;
    }
    .info{
      background: #FFFFFF;
      z-index: 99;
      position: relative;
      border-radius: 14px;
      padding: 71px 20px 46px;
      height: 369px;
      color: #202324;
      box-sizing: border-box;
      text-align: center;
      img{
        width: 94px;
        height: 94px;
        margin-bottom: 45px;
      }
      .state{
        font-size: 18px;
        font-weight: 600;
      }
      .money{
        color: #DC332E;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 31px;
      }
      .note{
        color: #575757;
        font-size: 13px;
        line-height: 18px;
      }
    }
    /deep/.van-popup{
      width: 300px;
      padding-bottom:  12px;
      text-align: center;
      .title{
        padding: 12px 0;
        font-size: 18px;
        border-bottom: 1px solid #eee;
      }
      .item{
        line-height: 40px;
        font-size: 13px;
      }
      .red{
        color: #056DF6;
        border-bottom: 1px solid #eee;
      }
    }
  }
  .payBtn{
    width: 277px;
    height: 48px;
    line-height: 48px;
    background: #056DF6;
    border-radius: 24px;
    margin: 0 auto;
    font-size: 21px;
    color: #fff;
    text-align: center;
  }
}
</style>