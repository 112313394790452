import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import 'amfe-flexible/index.js'
import 'vant/lib/index.css'; 
// 引入全局样式表
import './assets/global.css'
import { getQueryString } from './util/util' 
sessionStorage.setItem('referrerId',getQueryString("referrerId")?getQueryString("referrerId"): '')
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('user_token')
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = 'https://www.pszjk.com/'
    return
  }
  if(to.path === '/login'||to.path === '/proto'){
    next()
  }else if (to.path !== '/register'&& !token){
    next({name: 'Register'})
  } else {
    next() // 跳转到登录页
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
