<template>
  <div class="distribution">
    <van-nav-bar
      title="邀请好友"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="inviteWrap">
      <div class="blurBg"></div>
      <div class="inviteContentWrap">
        <div class="contentLeft">
          <img :src="user_info.avatar" alt="" />
          <div class="contentDetail">
            <h6>{{user_info.nickName}}</h6>
            <p>我的下线</p>
          </div>
        </div>
        <ul class="contentRight">
          <li @click="toDistribution">
            <h6 style="color: #202324;">{{referrerTotal}}</h6>
            <p>下线总计</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="inviteCodeWrap">
      <p class="title">我的推广二维码</p>
      <div class="content">
        <img :src="'https://api.pszjk.com/api/v1/user/invite/'+user_info.id" alt="" class="codeImg" id="imageWrapper"/>
        <div class="copyWrap">
          <div v-if="copyState" class="copyRight">链接已经复制到粘贴板</div>
          <div v-else>
            <img src="@/assets/img/icon/icon_coin.png" alt="">
            <span>
              分享赚钱
            </span>
          </div>
        </div>
        <div class="ass">
          <div @click="toSave" >
            <img src="@/assets/img/mine/icon_img.png" alt="">
            <span>保存相册</span>
          </div>
          <div v-if="!copyState" @click="handleCopy" class="copyCode" :data-clipboard-text="'https://m.pszjk.com?referrerId='+user_info.id+'#/register'">
            <img style="width: 18px;height: 18px;margin-right: 4px" src="@/assets/img/icon/icon_chain.png" alt="">
            <span>复制链接</span>
          </div>
          <div v-else @click="handleCopy" class="copyCode" :data-clipboard-text="'https://m.pszjk.com?referrerId='+user_info.id+'#/register'">
            <img style="width: 18px;height: 18px;margin-right: 4px" src="@/assets/img/icon/icon_chain_active.png" alt="">
            <span style="color: #1565D0">复制链接</span>
          </div>
        </div>
        <div class="cirle"></div>
        <div class="cirle rightCircle"></div>
      </div>
    </div>
    <div class="intro">
      <h4>分销介绍</h4>
      <div v-html="app_info.referDec"></div>
    </div>
  </div>
</template>

<script>
import { Icon, NavBar,Toast } from "vant";
import { GetReferrerStats } from '@/api/Profile'
import html2canvas from "html2canvas";
import Clipboard from 'clipboard';
export default {
  data() {
    return {
      copyState: false,
      shareList: [
        {
          imgUrl: require("./../../assets/img/mine/share(1).png"),
          name: "微信",
        },
        {
          imgUrl: require("./../../assets/img/mine/share(2).png"),
          name: "朋友圈",
        },
        {
          imgUrl: require("./../../assets/img/mine/share(3).png"),
          name: "短信",
        },
        {
          imgUrl: require("./../../assets/img/mine/share(4).png"),
          name: "QQ",
        },
      ],
      referrerTotal: ''
    };
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast
  },
  computed: {
    user_info(){
      return JSON.parse(this.$store.state.order.user_info)
    },
    app_info(){
      return JSON.parse(this.$store.state.order.app_info)
    }
  },
  mounted(){
    this.getReferrerStats()
  },
  methods: {
    // 跳转至分销中心
    toDistribution(){
      this.$router.push({
        name: 'Distributions',
        query:{
          tabNum: 'three'
        }
      })
    },
    // 获取下线总数
    getReferrerStats(){
      GetReferrerStats().then(res=>{
        this.referrerTotal = res.total
      }).catch(err=>{
      })
    },
    toSave () {
      html2canvas(document.getElementById("imageWrapper"),{width:80,height:80,useCORS:true, proxy:'https://api.pszjk.com/api/v1/user/invite/39feccd0-3b35-b046-0e06-e96a3dbf1f5d'}).then(canvas => {
        let saveUrl = canvas.toDataURL('image/png')
        let aLink = document.createElement('a')
        let blob = this.base64ToBlob(saveUrl)
        let evt = document.createEvent('HTMLEvents')
        evt.initEvent('click', true, true)
        aLink.download = '二维码.jpg'
        aLink.href = URL.createObjectURL(blob)
        aLink.click()
        if (aLink.href) {
          Toast.success('保存成功')
        }
      })
    },
    // 这里把图片转base64
    base64ToBlob (code) {
      let parts = code.split(';base64,')
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1])
      let rawLength = raw.length
      let uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    // 点击复制链接
    handleCopy(){
      this.copyState = true
      var clipboard = new Clipboard('.copyCode');
      clipboard.on('success', (e)=> {
        Toast("复制成功")
      });

      clipboard.on('error', function(e) {
      })
    },
    onClickLeft() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.distribution {
  background: #f3f8fb;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 80px;
  .inviteWrap {
    position: relative;
    margin-top: 10px;
    .blurBg {
      background: #e4e4e4;
      box-shadow: 0px 5px 4px 0px #e4e4e4;
      border-radius: 14px;
      border: 1px solid #f5f5f5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: 10px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 108px;
    }
    .inviteContentWrap {
      width: 349px;
      height: 108px;
      background: #ffffff;
      background: #fff url("./../../assets/img/mine/hand.png") no-repeat right;
      border-radius: 14px;
      filter: blur(0px);
      margin: 0 auto;
      text-align: center;
      padding: 24px 16px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 300;
      color: #868a8b;
      letter-spacing: 1px;
      display: flex;
      justify-content: space-between;
      .contentLeft {
        display: flex;
        img {
          width: 53px;
          height: 53px;
          border-radius: 53px;
          margin-right: 11px;
        }
        .contentDetail {
          text-align: left;
          h6 {
            font-size: 18px;
            font-weight: 600;
            color: #494949;
            line-height: 25px;
          }
          p {
            width: 70px;
            margin-left: -6px;
            height: 24px;
            background: #c4dbfe;
            border-radius: 24px;
            font-size: 10px;
            font-weight: 600;
            color: #3070e0;
            line-height: 24px;
            text-align: center;
            transform: scale(0.8);
            letter-spacing: 1px;
          }
        }
      }
      .contentRight {
        display: flex;
        width: 80px;
        align-items: center;
        justify-content: center;
        li {
          flex: 1;
          color: #2b2e2f;
          font-size: 13px;
          text-align: center;
          h6 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .inviteCodeWrap {
    background: #fff;
    margin: 0 17px;
    text-align: center;
    padding-top: 50px;
    border-radius: 20px;
    .title {
      color: #868a8b;
      line-height: 21px;
      letter-spacing: 1px;
      font-size: 15px;
      border-bottom: 1px dashed #a3a3a3;
      padding-bottom: 16px;
    }
    .content {
      padding: 0 17px 28px;
      text-align: center;
      position: relative;
      .codeImg {
        width: 120px;
        height: 120px;
        margin-bottom: 30px;
        margin-top: 40px;
      }
      .copyWrap{
        letter-spacing: 1px;
        margin-bottom: 40px;
        .copyRight{
          color: #1565D0;
          font-size: 15px;
          font-weight: normal;
        }
        div{
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: #004099;
          span{
            margin-left: 8px;
          }
        }
        img{
          width: 49px;
          height: 32px;
        }
      }
      ul {
        display: flex;
        li {
          flex: 1;
          img {
            width: 56px;
            height: 56px;
          }
          p {
            font-size: 10px;
            transform: scale(0.9);
            font-weight: 300;
            color: #2b2e2f;
            line-height: 14px;
            letter-spacing: 1px;
          }
        }
      }
      .ass{
        display: flex;
        padding: 0 40px;
        div{
          color: #2B2E2F;
          font-size: 13px;
          flex: 1;
          text-align: center;
          display: flex;
          align-items: center;
          height: 60px;
          justify-content: center;
          img{
            margin-right: 9px;
          }
        }
      }
      .cirle{
        width: 30px;
        height: 30px;
        background: #F3F8FB;
        position: absolute;
        top: 22%;
        left: -15px;
        border-radius: 15px;
      }
      .rightCircle{
        right: -15px;
        left: auto;
      }
    }
  }
  .intro{
    background: #fff;
    margin: 9px 17px;
    border-radius: 20px;
    padding: 50px 24px 60px;
    h4{
      text-align: center;
      font-size: 18px;
      color: #494949;
      margin-bottom: 22px;
    }
    p{
      text-indent: 2em;
      font-weight: 300;
      color: #202324;
      line-height: 17px;
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
}
</style>
