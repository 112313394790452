/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取企业详情
 * request: /api/v1/enterprises/{id}
 * method: GET
 * @param id - The identifier.
 * eg.
 */
export const Get = function(id) {
  let path = '/api/v1/enterprises/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'GET',
  })
}
/**
 * 获取企业列表
 * request: /api/v1/enterprises
 * method: GET
 * @param filter - 
 * @param typeId - 大类Id
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/enterprises';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 获取推荐企业
 * request: /api/v1/enterprises/recommend
 * method: GET
 * @param filter - 
 * @param typeId - 大类Id
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetRecommendList = function(queryParams) {
  let path = '/api/v1/enterprises/recommend';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 获取企业类别
 * request: /api/v1/enterprises/types
 * method: GET
 * eg.
 */
export const GetTypes = function() {
  let path = '/api/v1/enterprises/types';
  return request({
    url: path,
    method: 'GET',
  })
}