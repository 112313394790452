<template>
  <div class="distribution">
    <van-nav-bar
      title="分销中心"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="inviteWrap">
      <div class="blurBg"></div>
      <div class="inviteContentWrap">
        <div class="contentLeft">
          <img :src="user_info.avatar" alt="" />
          <div class="contentDetail">
            <h6>{{user_info.nickName}}</h6>
            <p>提现余额</p>
          </div>
        </div>
        <ul class="contentRight">
          <li style="text-align:right">
            <h6 style="color: #202324;">{{user_info.amount}}元</h6>
          </li>
        </ul>
      </div>
    </div>
    <van-tabs v-model="active" class="tabWrap">
      <van-tab title="佣金明细" name="one">
        <ul class="commissionWrap">
          <li v-for="(item,index) in commissionList" :key="index">
            <div class="into">
              <span>佣金收入</span>
              <span>+{{item.amount}}</span>
            </div>
            <div class="intoDetail">
              <span>{{item.creationTime}}</span>
              <span>{{item.remark}}</span>
            </div>
          </li>
          <li v-if="commissionList.length===0" class="emptyList">暂无数据</li>
        </ul>
      </van-tab>
      <van-tab title="提现明细" name="two">
        <ul class="listWrap">
          <li v-for="(item,index) in withdrawList" :key="index">
            <div class="item">
              <span class="state" v-if="item.status===0">待审核</span>
              <span class="state" v-else-if="item.status===1">通过</span>
              <span class="state" v-else-if="item.status===2">已拒绝</span>
              <span class="money">-{{item.amount}}</span>
            </div>
            <div class="item">
              <span class="time">{{item.creationTime}}</span>
              <!-- <span class="commission">佣金余额：{{item.userInfo.left}}</span> -->
            </div>
          </li>
          <li v-if="withdrawList.length===0" class="emptyList">暂无数据</li>
        </ul>
      </van-tab>
      <van-tab title="我的下线" name="three">
        <div class="tankList">
          <span @click="handleTabRank(index)" v-for="(item,index) in rankList" :key="index" :class="item.active?'active':''">{{item.title}}</span>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getSubAgent"
          class="offline"
        >
         <li v-for="(item,index) in offlineList" :key="index">
            <div class="infoLeft">
              <img :src="item.avatar" alt="">
            </div>
            <div class="infoRight">
              <p class="name">{{item.nickName}}</p>
              <p class="time">{{item.creationTime}}</p>
            </div>
          </li>
          <li v-if="offlineList.length===0" class="emptyList">暂无数据</li>
        </van-list>
          
        <!-- </ul> -->
      </van-tab>
    </van-tabs>
    <!-- <p class="withdraw">当前提现账号：989 921 921bb02 中国银行</p> -->
    <!-- 提交按钮 -->
    <div class="wantBtn" @click="handleWithDraw">我想提现</div>
  </div>
</template>

<script>
  import { Icon, NavBar, Tab, Tabs, List } from 'vant';
  import { GetList } from '@/api/Commission'
  import { GetRecordList } from '@/api/WithdrawRecord'
  import { GetSubAgent } from '@/api/Profile'
  export default {
    data(){
      return {
        active: 1,
        offlineActive: 1,
        rankList: [{
          title: '一级分销',
          active: true,
        }, {
          title: '二级分销',
          active: false,
        }],
        loading: false,
        finished: false,
        pageSize: 20,
        currentPage: 0,
        withdrawList: [],
        offlineList: [],
        commissionList: [],
        referrerTotal: ''
      }
    },
    computed: {
      user_info(){
        return JSON.parse(this.$store.state.order.user_info)
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [List.name]: List
    },
    mounted(){
      this.getRecordList()
      this.getList()
      this.active = this.$route.query.tabNum
    },
    methods: {
      // 切换分销tab
      handleTab(index){
        this.offlineActive = index + 1
        this.currentPage = 0
        this.getSubAgent()
      },
      getSubAgent(){
        this.currentPage += 1
        let params = {
          Level: this.offlineActive,
          SkipCount: (this.currentPage - 1) * this.pageSize,
          MaxResultCount: this.pageSize
        }
        GetSubAgent(params).then(res=>{
          this.offlineList = res.items
          this.loading = true
          this.finished = true
        }).catch(err=>{
        })
      },
      getRecordList(){
        GetRecordList().then(res=>{
          this.withdrawList = res.items
        }).catch(err=>{
        })
      },
      getList(){
        GetList().then(res=>{
          this.commissionList = res.items
        }).catch(err=>{
        })
      },
      handleWithDraw(){
        this.$router.push("/withDraw")
      },
      // 切换分销分类
      handleTabRank(index){
        this.rankList.map((item)=>{
          item.active = false
        })
        this.rankList[index].active = true
        this.offlineActive = index + 1
        this.currentPage = 0
        this.getSubAgent()
      },
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.distribution{
  background: #fff;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 40px;
  .inviteWrap {
    position: relative;
    margin-top: 10px;
    .blurBg {
      background: #e4e4e4;
      box-shadow: 0px 5px 4px 0px #e4e4e4;
      border-radius: 14px;
      border: 1px solid #f5f5f5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 108px;
    }
    .inviteContentWrap {
      width: 349px;
      height: 108px;
      background: #ffffff;
      background: #fff url("./../../assets/img/mine/hand.png") no-repeat right;
      border-radius: 14px;
      filter: blur(0px);
      margin: 0 auto;
      text-align: center;
      padding: 24px 16px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 300;
      color: #868a8b;
      letter-spacing: 1px;
      display: flex;
      justify-content: space-between;
      .contentLeft {
        display: flex;
        img {
          width: 53px;
          height: 53px;
          border-radius: 53px;
          margin-right: 11px;
        }
        .contentDetail {
          text-align: left;
          h6 {
            font-size: 18px;
            font-weight: 600;
            color: #494949;
            line-height: 25px;
          }
          p {
            width: 70px;
            margin-left: -6px;
            height: 24px;
            background: #c4dbfe;
            border-radius: 24px;
            font-size: 10px;
            font-weight: 600;
            color: #3070e0;
            line-height: 24px;
            text-align: center;
            transform: scale(0.8);
            letter-spacing: 1px;
          }
        }
      }
      .contentRight {
        display: flex;
        flex: 1;
        align-items: center;
        li {
          flex: 1;
          color: #2b2e2f;
          font-size: 13px;
          h6 {
            font-size: 16px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .tabWrap{
    margin-top: 20px;
    // padding-bottom: 40px;
    /deep/.van-tabs__nav{
      padding-left: 60px;
      padding-right: 60px;
      .van-tab--active{
        font-size: 18px;
      }
    }
    /deep/.van-tabs__line{
      width: 80px;
      background-color: #C4DBFE !important;
    }
    .listWrap{
      padding: 10px 20px;
      box-sizing: border-box;
      li{
        padding: 14px 0;
        .item{
          display: flex;
          justify-content: space-between;
          span{
            font-weight: 500;
            line-height: 18px;
          }
          .time,.commission{
            font-size: 13px;
            color: #818282;
            margin-top: 5px;
          }
          .state,.money{
            font-size: 17px;
            color: #343536;
          }
          .money{
            color: #DC332E;
          }
        }
      }
      .emptyList{
        text-align: center;
        font-size: 12px;
        color: #999;
      }
    }
    .tankList{
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      justify-content: space-around;
      padding: 0 60px;
      box-sizing: border-box;
      span{
        padding: 5px 12px;
        font-size: 10px;
        font-weight: 600;
        color: #A7A8A8;
        line-height: 15px;
        background: #F3F8FB;
        border-radius: 20px;
        transform: scale(.9);
        margin-bottom: 4px;
      }
      .active{
        background: #C4DBFE;
        color: #3070E0;
      }
    }
    .offline{
      padding: 0 38px;
      li{
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        margin: 28px 0;
        .infoLeft{
          text-align: center;
          width: 47px;
          margin-right: 12px;
          img{
            width: 47px;
            height: 47px;
            border-radius: 50px;
          }
          .money{
            color: #DC332E;
            font-size: 17px;
            line-height: 18px;
          }
          .subText{
            color: #818282;
            font-size: 10px;
          }
        }
        .infoRight{
          flex: 1;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          .name{
            color: #343536;
            font-size: 17px;
            margin-bottom: 6px;
          }
          .time{
            color: #818282;
            transform: scale(.9);
            margin-left: -10px;
            font-weight: 300;
          }
        }
      }
      .emptyList{
        justify-content: center;
        font-size: 12px;
        color: #999;
      }
    }
    .commissionWrap{
      padding: 0 20px;
      li{
        font-size: 10px;
        margin: 28px 0;
        .into{
          display: flex;
          justify-content: space-between;
          color: #343536;
          font-size: 17px;
          margin-bottom: 5px;
        }
        .intoDetail{
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          color: #818282;
        }
      }
      .emptyList{
        text-align: center;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .withdraw{
    margin-bottom: 28px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #818282;
    line-height: 18px;
    text-align: center;
  }
  .wantBtn{
    width: 322px;
    height: 49px;
    background: #FAF4DC;
    border-radius: 4px;
    margin: 18px auto;
    font-size: 17px;
    font-weight: 500;
    color: #F2916F;
    line-height: 49px;
    text-align: center;
  }
}
</style>