const state = {
  user_token: sessionStorage.getItem("user_token"),
  refresh_token: sessionStorage.getItem("refresh_token"),
  user_info: sessionStorage.getItem("user_info"),
  app_info: sessionStorage.getItem("app_info")||'',
  tabTypeList: sessionStorage.getItem("tabTypeList")||[],
  tankProduct: sessionStorage.getItem("tankProduct")||[],
  tankInfo: sessionStorage.getItem("tankInfo")||[],
};

const mutations = {
  SET_USER_TOKEN: (state, user_token) => {
    state.user_token = user_token;
    sessionStorage.setItem('user_token', state.user_token);
  },
  SET_REFRESH_TOKEN: (state, refresh_token) => {
    state.refresh_token = refresh_token;
    sessionStorage.setItem('refresh_token', state.refresh_token);
  },
  SET_USER_INFO: (state, user_info) => {
    state.user_info = user_info;
    sessionStorage.setItem('user_info', state.user_info);
  },
  SET_APP_INFO: (state, app_info) => {
    state.app_info = app_info;
    sessionStorage.setItem('app_info', state.app_info);
  },
  SET_TABTYPE_LIST: (state, tabTypeList) => {
    state.tabTypeList = tabTypeList;
    sessionStorage.setItem('tabTypeList', state.tabTypeList);
  },
  SET_TANK_PRODUCT: (state, tankProduct) => {
    state.tankProduct = tankProduct;
    sessionStorage.setItem('tankProduct', state.tankProduct);
  },
  SET_TANK_INFO: (state, tankInfo) => {
    state.tankInfo = tankInfo;
    sessionStorage.setItem('tankInfo', state.tankInfo);
  },
};

const actions = {
  setUserToken({ commit }, params) {
    commit('SET_USER_TOKEN', params);
  }, 
  setRefreshToken({ commit }, params) {
    commit('SET_REFRESH_TOKEN', params);
  },
  setUserInfo({ commit }, params) {
    commit('SET_USER_INFO', params);
  },
  setAppInfo({ commit }, params) {
    commit('SET_APP_INFO', params);
  },
  setTabTypeList({ commit }, params) {
    commit('SET_TABTYPE_LIST', params);
  },
  setTankInfo({ commit }, params) {
    commit('SET_TANK_INFO', params);
  },
  setTankProduct({ commit }, params) {
    commit('SET_TANK_PRODUCT', params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
