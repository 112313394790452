<template>
  <div class="recommend"> 
    <header>
      <div class="title">
        <van-icon class="backIcon" name="arrow-left" @click="onClickLeft"/>
        <h3>名企推介</h3>
        <div class="collectWrap" v-show="!collectState" @click="handleCollect(info.id)">
          <van-icon name="like-o" color="#ffffff" style="line-height:50px;"/>
          <span>关注企业</span>
        </div>
        <div class="collectWrap" v-show="collectState" @click="handleCancelCollect(info.id)">
          <van-icon name="like" color="#FFE04B" style="line-height:50px;" />
          <span>取消关注</span>
        </div>
      </div>
    </header>
    <div class="companyWrap">
      <div class="contact">
        <h3>{{info.name}}</h3>
        <van-button class="ask" size="mini"><a :href="'tel:'+info.contactPhone" style="color: #F2916F">马上咨询</a></van-button>
      </div>
      <ul class="introduce">
        <li>
          <h4>业务简介</h4>
          <p v-html="info.intro"></p>
        </li>
        <li>
          <h4>公司规模</h4>
          <p v-html="info.desc"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Button, Icon, NavBar, Toast } from 'vant';
import Vue from 'vue';
Vue.use(Toast);
import { Get } from '@/api/Enterprise'
import { Create, DeleteByEnterpriseId } from '@/api/FavoriteEnterprise'
export default {
  name: 'RecommendDetail',
  data(){
    return{
      tankId: '',
      collectState: false,
      info: ''
    }
  },
  created(){
    this.tankId = this.$route.query.id;
    this.getDetail()
  },
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  methods: {
    // 点击收藏
    handleCollect(id){
      let params = {
        enterpriseId: id,
        remark: ''
      }
      Create(params).then(res=>{
        Toast('收藏成功')
        this.collectState = !this.collectState
      }).catch(err=>{
        Toast('收藏失败')
      })
    },
    // 取消收藏
    handleCancelCollect(id){
      DeleteByEnterpriseId(id).then(res=>{
        Toast('取消收藏');
        this.collectState = !this.collectState
      }).catch(err=>{
        Toast('取消收藏失败')
      })
    },
    getDetail(){
      Get(this.tankId).then(res=>{
        this.info = res
        this.collectState = res.isFavorite
      }).catch(err=>{
        Toast('请求详情失败')
      })
    },
    onClickLeft(){
      this.$router.back(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.recommend{
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;
  header{
    padding: 0 20px;
    height: 197px;
    background: url(../../assets/img/headDecoration.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .title{
      h3{
        line-height: 50px;
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
      .backIcon{
        font-size: 16px;
        line-height: 50px;
        position: absolute;
        left: 16px;
        color: #fff;
      }
      .collectWrap{
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 0px;
        span{
          color: #fff;
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }
  }
  .companyWrap{
    background: #fff;
    margin-top: 24px;
    .logo{
      font-size: 0;
      padding: 0 16px;
      img{
        width: 160px;
        display: block;
        margin: 20px 0 6px;
      }
    }
    .contact{
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      h3{
        font-size: 18px;
      }
      button{
        border-radius: 6px;
        background: #FAF4DC;
        color: #F2916F;
        font-size: 13px;
        /deep/.van-button__text{
          transform: scale(.9);
          letter-spacing: 1px;
        }
      }
    }
    .introduce{
      background: #F3F8FB;
      li{
        margin-bottom: 13px;
        background: #fff;
        padding: 18px 16px;
      }
      h4{
        line-height: 40px;
        font-weight: normal;
        font-size: 16px;
        color: #868A8B;
        font-family: "\5FAE\8F6F\96C5\9ED1",sans-serif;
      }
      p{
        letter-spacing: .4px;
        line-height: 22px;
        font-size: 12px;
        color: #202324;
      }
    }
  }
}
</style>