<!--
 * @Author: your name
 * @Date: 2021-07-18 01:58:10
 * @LastEditTime: 2021-07-18 02:22:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \testc:\Users\admin\Desktop\expert-tank\src\views\Search\Search.vue
-->
<template>
  <div class="search">
    <div class="searchWrap">
      <img src="@/assets/img/search/icon_back.png" alt="" class="backImg" @click="handleBack">
      <van-search v-model="searchVal" shape="round" placeholder="请输入搜索关键词" show-action @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <van-tabs v-model="active" class="tabWrap">
      <van-tab title="全部">
        <div class="tabContent">
          <div class="wrap" v-show="!searchState">
            <p class="title">搜索历史<van-icon name="delete-o" @click="handleClear"/></p>
            <ul class="historyList">
              <li v-for="(item,index) in historyList.length>10?historyList.slice(0,10):historyList" :key="index" @click="handleHistoryItem(item)">{{item}}</li>
            </ul>
          </div>
          <div class="wrap" v-show="!searchState">
            <p class="title" style="margin-bottom: 24px;">推荐企业</p>
            <ul class="recommendEnterprisesList">
              <li @click="toEnterprisesDetail(item.id)" v-for="(item,index) in recommendEnterprisesList" :key="index">{{item.name}}</li>
            </ul>
          </div>
          <div class="wrap" v-show="!searchState">
            <p class="title" style="margin-bottom: 24px;">推荐专家</p>
            <ul class="recommendExpertList">
              <li @click="toExpertDetail(item.id)" v-for="(item,index) in recommendExpertList" :key="index">
                <h6 class="name">{{item.name}}</h6>
                <p class="type">{{item.titleCert}}</p>
                <p class="type">{{item.practiceCert}}</p>
              </li>
            </ul>
          </div>
          <div class="wrap" v-show="searchState">
            <h5><p>{{searchVal}} - 专家信息（{{expertCount}}）</p><span @click="active=1">全部</span></h5>
            <ul class="resulteList">
              <li v-for="(item,index) in expertList.length>3?expertList.slice(0,3):expertList" :key="index" @click="toExpertDetail(item.id)">{{item.title}}</li>
              <li v-show="expertList.length===0">暂无内容</li>
            </ul>
          </div>
          <div class="wrap" v-show="searchState">
            <h5><p>{{searchVal}} - 评审案例（{{reviewCount}}）</p><span @click="active=2">全部</span></h5>
            <ul class="resulteList">
              <li v-for="(item,index) in reviewList.length>3?reviewList.slice(0,3):reviewList" :key="index" @click="toReviewDetail(item.id)">{{item.title}}</li>
              <li v-show="reviewList.length===0">暂无内容</li>
            </ul>
          </div>
          <div class="wrap" v-show="searchState">
            <h5><p>{{searchVal}} - 企业信息（{{enterpriseCount}}）</p><span @click="active=3">全部</span></h5>
            <ul class="resulteList">
              <li v-for="(item,index) in enterprisesList.length>3?enterprisesList.slice(0,3):enterprisesList" :key="index" @click="toEnterprisesDetail(item.id)">{{item.title}}</li>
              <li v-show="enterprisesList.length===0">暂无内容</li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="专家信息">
        <div class="tabContent">
          <div class="wrap">
            <h5>{{searchVal}} - 专家信息（{{expertCount}}）</h5>
            <ul class="resulteList">
              <li v-for="(item,index) in expertList2" :key="index" @click="toExpertDetail(item.id)">{{item.title||item.name}}</li>
              <li v-show="expertList2.length===0">暂无内容</li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="评审案例">
        <div class="tabContent">
          <div class="wrap">
            <h5>{{searchVal}} - 评审案例（{{reviewCount}}）</h5>
            <ul class="resulteList">
              <li v-for="(item,index) in reviewList2" :key="index" @click="toReviewDetail(item.id)">{{item.title||item.name}}</li>
              <li v-show="reviewList2.length===0">暂无内容</li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="企业信息">
        <div class="tabContent">
          <div class="wrap">
            <h5>{{searchVal}} - 企业信息（{{enterpriseCount}}）</h5>
            <ul class="resulteList">
              <li v-for="(item,index) in enterprisesList2" :key="index" @click="toEnterprisesDetail(item.id)">{{item.title||item.name}}</li>
              <li v-show="enterprisesList2.length===0">暂无内容</li>
            </ul>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import { Search, Tab, Tabs, Icon } from 'vant';
  import  { MagicSearch } from '@/api/Main'
  import { GetRecommendList } from '@/api/Enterprise'
  import { GetRecommendList as GetExpertRecommendList, GetList } from '@/api/Expert'
  import { GetList as GetEnterpriseList } from '@/api/Enterprise';
  import { GetList as GetReviewList } from '@/api/Review';
  export default {
    data(){
      return {
        recommendEnterprisesList:[],
        recommendExpertList: [],
        active: 0,
        searchVal: '',
        searchState: false,
        expertList: [],
        expertList2: [],
        enterprisesList: [],
        reviewList: [],
        reviewList2: [],
        enterprisesList2: [],
        historyList: JSON.parse(sessionStorage.getItem("historyList"))||[],
        enterpriseCount: '',
        expertCount: '',
        reviewCount: ''
      }
    },
    watch: {
      searchVal(val){
        if(!val){
          this.searchState = false
          this.expertList2 = []
          this.reviewList2 = []
          this.enterprisesList2= []
          this.enterpriseCount = ''
          this.expertCount = ''
          this.reviewCount = ''
        }
      }
    },
    mounted(){
      this.getRecommendList()
      this.getExpertRecommendList()
    },
    methods: {
      getList(index){
        let params = {
          SkipCount: 0,
          MaxResultCount: 100,
          filter: this.searchVal
        }
        if(index===1){
          GetList(params).then((res)=>{
            this.expertList2 = res.items
          }).catch(err=>{
          })
        }else if(index===2){
          GetReviewList(params).then((res)=>{
            this.reviewList2 = res.items
          }).catch(err=>{
          })
        }else if(index===3){
          GetEnterpriseList(params).then((res)=>{
            this.enterprisesList2 = res.items
          }).catch(err=>{
          })
        }
      },
      getExpertRecommendList(){
        GetExpertRecommendList({MaxResultCount:3}).then(res=>{
          this.recommendExpertList = res.items
        }).catch(err=>{
        })
      },
      getRecommendList(){
        GetRecommendList({MaxResultCount:2}).then(res=>{
          this.recommendEnterprisesList = res.items
        }).catch(err=>{
        })
      },
      handleHistoryItem(val){
        this.searchVal = val
        this.onSearch()
      },
      // 去专家详情
      toExpertDetail(id){
        this.$router.push({
          name: 'TankDetail',
          query:{
            id: id,
          }
        })
      },
      // 去评审案例详情
      toReviewDetail(id){
        this.$router.push({
          name: 'CaseDetail',
          query:{
            id: id,
          }
        })
      },
      // 去企业详情
      toEnterprisesDetail(id){
        this.$router.push({
          name: 'RecommendDetail',
          query:{
            id: id,
          }
        })
      },
      // 清除历史记录
      handleClear(){
        this.historyList = []
        sessionStorage.setItem("historyList",JSON.stringify(this.historyList))
      },
      // 搜索操作
      onSearch(){
        this.searchState = true
        if(this.searchVal!==''){
          this.historyList.map((item,index)=>{
            if(this.searchVal===item){
              this.historyList.splice(index,1)
            }
          })
          this.historyList.unshift(this.searchVal)
          sessionStorage.setItem("historyList",JSON.stringify(this.historyList))
        } 
        MagicSearch({filter: this.searchVal}).then(res=>{
          this.enterpriseCount = res.enterpriseCount
          this.expertCount = res.expertCount
          this.reviewCount = res.reviewCount
          this.expertList = res.experts
          this.reviewList = res.reviews
          this.enterprisesList = res.enterprises
          this.getList(1)
          this.getList(2)
          this.getList(3)
        }).catch(err=>{
        })
      },
      // 返回上级页面
      handleBack(){
        this.$router.back(-1)
      }
    },
    components: {
      [Search.name]: Search,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Icon.name]: Icon
    }
  }
</script>

<style lang="less" scoped>
.search{
  width: 100vw;
  min-height: 100vh;
  letter-spacing: 1px;
  background: #F5F6F7;
  .searchWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    .backImg{
      width: 13px;
      height: 22px;
    }
    /deep/.van-search{
      flex: 1;
      background: rgba(0, 0, 0, 0);
      .van-search__content{
        border: 1px solid #D6D6D6;
        .van-icon-search{
          font-size: 20px;
          color: #838383;
        }
      }
      .van-search__action{
        color: #8C8C8C;
        font-size: 14px;
      }
    }
  }
  .tabWrap{
    background: rgba(0, 0, 0, 0);
    /deep/.van-tabs__nav{
      background: rgba(0, 0, 0, 0) ;
    }
    /deep/.van-tabs__line{
      background-color: #143DF1 !important;
    }
    .tabContent{
      margin-top: 12px;
      .historyList{
        display: flex;
        flex-wrap: wrap;
        li{
          padding: 8px 12px;
          font-size: 15px;
          color: #202324;
        }
      }
      .resulteList{
        h5{
          color: #090909;
          font-weight: bold;
          font-size: 18px;
        }
        li{
          
          padding: 12px 0;
          font-size: 15px;
          color: #202324;
        }
      }
      .wrap{
        padding: 20px 16px 20px 30px;
        background: #FFFFFF;
        margin-bottom: 6px;
        h5{
          display: flex;
          justify-content: space-between;
          span{
            font-weight: normal;
          }
        }
        .title{
          font-size: 18px;
          color: #616161;
          line-height: 25px;
          letter-spacing: 1px;
          display: flex;
          justify-content: space-between;
          font-weight: 300;
          margin-bottom: 8px;
          /deep/.van-icon{
            color: #999;
          }
        }
        .recommendEnterprisesList{
          li{
            font-size: 15px;
            color: #202324;
            margin-bottom: 12px;
          }
          li:last-child{
            margin-bottom: 0;
          }
        }
        .recommendExpertList{
          display: flex;
          flex-wrap: wrap;
          li{
            width: 33%;
            .name{
              font-weight: normal;
              font-size: 15px;
              color: #1D2021;
              line-height: 21px;
            }
            .type{
              color: #2B2E2F;
              font-size: 10px;
            }
          }
        }
      }
    }
    
  }
}
</style>