<template>
  <div>
    <van-nav-bar
      title="服务协议"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-html="appInfo" style="padding: 4px 24px;"></div>
  </div>
</template>

<script>
  import { Icon, NavBar } from 'vant';
  export default {
    computed: {
      appInfo(){
        return JSON.parse(this.$store.getters.app_info).protocol
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
    },
    mounted(){
    },
    methods: {
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>