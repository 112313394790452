<template>
  <div class="mineWrap">
    <header>更改密码</header>
    <form class="formWrap">
      <div class="formItem">
        <label for="">手机号</label>
        <input type="text" placeholder="请输入手机号" v-model="username">
      </div>
      <div class="formItem">
        <label for="">验证码</label>
        <input type="text" placeholder="请输入验证码" v-model="captcha">
        <button @click.prevent="getCaptcha">获取验证码</button>
      </div>
      <div class="formItem">
        <label for="">新密码</label>
        <input type="password" placeholder="请输入密码" v-model="password">
      </div>
      <div>
        <button @click.prevent="handleReset">重置密码</button>
      </div>
    </form>
  </div>
</template>

<script>
// import { Icon } from 'vant';
import { resetPassword, getResetToken } from '@/api/Account'
import { getCaptcha } from '@/api/Captcha'
export default {
  name: 'Mine',
  data(){
    return{
      username: '18637760686',
      captcha: 888888,
      password: '123456',
      token: ''
    }
  },
  components: {
    // [Icon.name]: Icon
  },
  methods: {
    getToken(){
      let params = {
        phoneNumberOrEmail: this.username,
        captcha: this.captcha,
      }
      getResetToken(params).then(res => {
        this.token = res
        this.requestReset()
      }).catch(error => {
      });
    },
    getCaptcha(){
      let params = {
        phoneNumberOrEmail: this.username,
        type: 'resetPassword',
      }
      getCaptcha(params).then(res => {
      }).catch(error => {
      });
    },
    handleReset(){
      this.getToken()
    },
    requestReset(){
      let params = {
        phoneNumberOrEmail: this.username,
        password: this.password,
        token: this.token
      }
      resetPassword(params).then(res => {
        sessionStorage.clear()
        this.$router.push('/login')
      }).catch(error => {
      });
    }
  }
}
</script>
<style lang="less" scoped>
.mineWrap{
  height: 100vh;
  background: #f4f4f4;
  header{
    height: 50px;
    background: #fff;
    line-height: 50px;
    text-align: center;
    border-bottom: .5px solid #f4f4f4;
    font-size: 18px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .formWrap{
    width: 300px;
    margin: 80px auto;
    .formItem{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      label{
        width: 90px;
      }
      button{
        margin-left: 8px;
        padding: 2px 4px;
        font-size: 12px;
        position: absolute;
        right: 0;
      }
      input{
        flex: 1;
        height: 40px;
        border: 1px solid #d5d5d5;
        padding-left: 12px;
        border-radius: 4px;
      }
    }
  }
}
</style>