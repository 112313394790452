/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取验证码
 * request: /api/v1/captcha
 * method: GET
 * @param phoneNumberOrEmail - The phone number or email.
 * @param type - 类型：(login:登录/changePassword:修改密码/resetPassword:找回密码/register:注册)
 * eg.
 */
export const Get = function(queryParams) {
  let path = '/api/v1/captcha';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}