/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 添加收藏
 * request: /api/v1/favorite/enterprises
 * method: POST
 */
export const Create = function(data) {
  let path = '/api/v1/favorite/enterprises';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 获取收藏企业列表
 * request: /api/v1/favorite/enterprises
 * method: GET
 * @param filter - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/favorite/enterprises';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 移除收藏
 * request: /api/v1/favorite/enterprises/{enterpriseId}
 * method: DELETE
 * @param enterpriseId - 
 */
export const DeleteByEnterpriseId = function(enterpriseId) {
  let path = '/api/v1/favorite/enterprises/{enterpriseId}';
  path = path.replace('{enterpriseId}', enterpriseId)
  return request({
    url: path,
    method: 'DELETE',
  })
}
/**
 * 修改收藏
 * request: /api/v1/favorite/enterprises/{enterpriseId}
 * method: PUT
 * @param enterpriseId - The expert identifier.
 */
export const UpdateByEnterpriseId = function(enterpriseId, data) {
  let path = '/api/v1/favorite/enterprises/{enterpriseId}';
  path = path.replace('{enterpriseId}', enterpriseId)
  return request({
    url: path,
    method: 'PUT',
    data,
  })
}