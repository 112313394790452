<template>
  <div class="editInfo">
     <van-nav-bar
      title="编辑资料"
      left-arrow
      @click-left="onClickLeft">
    </van-nav-bar>
    <div class="infoList">
      <van-cell center title="更改头像" is-link class="avatarCell">
        <template #default>
          <img :src="user_info.avatar" alt="" @click="handleUpload" class="avatar" v-show="user_info.avatar">
          <img src="./../../assets/img/avatar.jpg" alt="" @click="handleUpload" class="avatar" v-show="!user_info.avatar">
          <van-uploader v-show="false" class="avatar" ref="uploader" :preview-full-image="false" :deletable="false" :after-read="afterRead" v-model="fileList" :max-count="1" multiple  :max-size="500 * 1024" @oversize="onOversize" />
        </template>
      </van-cell>
      <van-cell center title="昵称" is-link :value="user_info.nickName||'用户'" @click="popUp('昵称',user_info.nickName,'nickName')"/>
      <van-cell center title="手机号" is-link :value="user_info.phoneNumber||''" @click="popUp('手机号',user_info.phoneNumber,'phoneNumber')"/>
      <van-cell center title="公司" is-link :value="user_info.company||''" @click="popUp('公司',user_info.company,'company')"/>
      <van-cell center title="职务" is-link :value="user_info.title||''" @click="popUp('职务',user_info.title,'title')"/>
      <van-cell center title="性别" is-link :value="divChoose(user_info.sex)||''" @click="popUp2"/>
      <van-cell center title="地区" is-link :value="user_info.city||''" @click="popUp('地区',user_info.city,'city')"/>
    </div>
    <div class="confirmBtn" @click="handleEdit">确认更改</div>
    <van-dialog v-model:show="editPopShow" :title="editTitle" show-cancel-button  @confirm="handleConfirmEdit">
      <van-field v-model="editContent" placeholder="请输入要修改的内容" />
    </van-dialog> 
    <van-popup v-model:show="sexPopShow" position="bottom" :style="{ height: '30%' }">
      <van-picker
        title="性别"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="sexPopShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
  import { UpdateFields, Get, Upload } from '@/api/Profile'
  import { getCurrentInstance } from "vue";
  import { Icon, NavBar, Field, Uploader, Toast, Cell, Popup, Dialog, Picker } from 'vant';
  import { get } from 'vant/lib/utils';
  export default {
    data(){
      return {
        fileList: [],
        fileUploadState: false,
        editPopShow: false,
        sexPopShow: false,
        editTitle:'修改',
        editContent: '',
        info: {
          avatar: require('./../../assets/img/avatar.jpg'),
          name: '爱吃鱼的小鱼眼',
          phoneNumber: '17737307599',
          company: '创想者网络科技有限公司',
          post: '市场部经理',
          sex: '男',
          address: '广西南宁'
        },
        orderObj: '',
        columns: [{
          text: '男',
          value: 1
        }, {
          text: '女',
          value: 2
        }],
        user_info: {}
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Toast.name]: Toast,
      [Cell.name]: Cell,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Dialog.Component.name]: Dialog.Component
    },
    mounted(){
      this.user_info = JSON.parse(this.$store.state.order.user_info)
    },
    setup() {
      let this_ = getCurrentInstance()
      // 暴露给模板
      return {
        this_
      }
    },
    methods: {
      // 手机号正则
      testPhone(val){
        let value = val
        let reg = /^1[3,5,6,7,8,9]\d{9}$/
        return reg.test(value)
      },
      divChoose(value){
        if(value==0){
          return ''
        }else if(value==1){
          return '男'
        }else if(value==2){
          return '女'
        }
      },
      onCancel() {
        this.sexPopShow = false
      },
      // 点击确认更改按钮
      handleEdit(){
        let params = {
          nickName: this.user_info.nickName,
          avatar: this.user_info.avatar,
          phoneNumber: this.user_info.phoneNumber,
          company: this.user_info.company,
          title: this.user_info.title,
          sex: this.user_info.sex,
          city: this.user_info.city,
        }
        UpdateFields(params).then(res=>{
          this.refreshInfo()
        }).catch(err=>{
        })
      },
      refreshInfo(){
        Get().then(res =>{
          let data = res
          this.user_info = data
          Toast.success('修改成功');
          this.$store.dispatch("order/setUserInfo", JSON.stringify(data))
        }).catch(err=>{
        })
      },
      handleConfirmEdit(done){
        if(this.orderObj=='phoneNumber'){
          let state = this.testPhone(this.editContent)
          if(!state){
            Toast.fail('请输入正确的手机号')
            return
          }
        }
        this.user_info[this.orderObj] = this.editContent
      },
      // 点击确定选择性别
      onConfirm(obj) {
        this.user_info.sex = obj.value
        this.sexPopShow = false
      },
      // 点击弹性别选择框
      popUp2(){
        this.sexPopShow = true
      },
      // 点击修改信息
      popUp(txt,val,obj){
        this.orderObj = obj
        this.editPopShow = true
        this.editContent = val
        this.editTitle = '修改'+txt
      },
      // 点击头像进行更换头像操作
      handleUpload(){
        if(this.fileUploadState){
          this.fileList = [] 
        }
        this.$nextTick(()=>{
          this.$refs.uploader.chooseFile()
        })
      },
      // 限制上传图片大小
      onOversize(file) {
        Toast('文件大小不能超过 500kb');
      },
      // 上传图片
      afterRead(file) {
        let param = new FormData()  // 创建form对象
        param.append('file', file.file)  // 通过append向form对象添加数据
        Upload(param).then(res=>{
          this.user_info.avatar = res
          this.$set(this.user_info,'avatar',res)
          let params = {
            nickName: this.user_info.nickName,
            avatar: this.user_info.avatar,
            phoneNumber: this.user_info.phoneNumber,
            company: this.user_info.company,
            title: this.user_info.title,
            sex: this.user_info.sex,
            city: this.user_info.city,
          }
          UpdateFields(params).then(()=>{
            this.refreshInfo()
          }).catch(err=>{
          })
        }).catch(err=>{
        })
        // 此时可以自行将文件上传至服务器
        if(file){
          this.fileUploadState = true
        }
      },
      // 返回上级页面
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.editInfo{
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(43, 132, 247, .79) 0%, #F3F8FB 100%, #F3F8FB 100%);
  /deep/.van-nav-bar{
    background: rgba(0, 0, 0, 0);
    .van-nav-bar__title{
      color: #fff;
    }
    .van-icon{
      color: #fff;
    }
  }
  /deep/.van-hairline--bottom::after{
    border-bottom-width: 0
  }
  .infoList{
    margin: 4px 20px;
    background: #fff;
    border-radius: 14px;
    padding: 18px 0;
    .van-cell::after{
      border-width: 0;
    }
    /deep/.van-cell{
      background: rgba(0, 0, 0, 0);
      padding: 20px 16px 20px 23px;
      .van-cell__right-icon{
        line-height: 24px !important;
      }
      .van-cell__title{
        flex: none;
        width: 90px;
        span{
          font-size: 17px;
          color: #343536;
        }
      }
      .van-cell__value{
        flex: 1;
      }
    }
    .avatarCell{
      padding-bottom: 0;
      padding-top: 0;
      .van-cell__value{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
      }
      /deep/.van-uploader__upload,/deep/.van-uploader__preview-image{
        width: 49px;
        height: 49px;
        border-radius: 50px;
        margin: 0;
      }
      .avatar, .van-uploader__upload{
        width: 49px;
        height: 49px;
        border-radius: 50px;
      }
    }
  }
  /deep/.popupWrap{
    padding: 30px;
  }
  .confirmBtn{
    width: 322px;
    height: 49px;
    background: #FAF4DC;
    border-radius: 4px;
    margin: 18px auto;
    font-size: 17px;
    font-weight: 500;
    color: #F2916F;
    line-height: 49px;
    text-align: center;
  }
}
</style>