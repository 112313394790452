/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取订单列表
 * request: /api/v1/order
 * method: GET
 * @param filter - 
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/order';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 创建订单
 * request: /api/v1/order
 * method: POST
 */
export const Create = function(data) {
  let path = '/api/v1/order';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 获取订单
 * request: /api/v1/order/{id}
 * method: GET
 * @param id - The identifier.
 * eg.
 */
export const Get = function(id) {
  let path = '/api/v1/order/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'GET',
  })
}
/**
 * 删除订单
 * request: /api/v1/order/{id}
 * method: DELETE
 * @param id - The identifier.
 */
export const Delete = function(id) {
  let path = '/api/v1/order/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'DELETE',
  })
}
/**
 * 发起支付
 * request: /api/v1/order/{id}/pay
 * method: POST
 * @param id - The identifier.
 */
export const Pay = function(id, data) {
  let path = '/api/v1/order/{id}/pay';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 取消订单
 * request: /api/v1/order/{id}/cancel
 * method: POST
 * @param id - The identifier.
 */
export const Cancel = function(id, data) {
  let path = '/api/v1/order/{id}/cancel';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'POST',
    data,
  })
}