<template>
  <div class="tank">
    <header>
      <h3>专家库</h3>
      <van-icon name="search"  @click="toSearch"/>
      <div class="floatWrap" @click="toAcquisitionLetter"></div>
    </header> 
    <div class="companyWrap">
      <van-tabs v-model="active" @click="handleTab">
        <van-tab title="全部" name="全部">
          <van-list
            v-model="loading"
            :finished="finished"
            loading-text="加载中"
            finished-text="暂无数据"
            @load="getList"
            class="companyList"
          >
            <li v-for="(item,index) in companyList" :key="index" @click="toDetail(item.id)">
              <div class="imgWrap">
                <img :src="item.photo" alt="">
              </div>
              <div class="companyDetail">
                <p class="peopleName">{{item.name}}</p>
                <div class="honorList">
                  <p>{{item.titleCert}}</p>
                  <p>{{item.practiceCert}}</p>
                </div>
              </div>
            </li>
          </van-list>
        </van-tab>
        <van-tab :name="item.name" :title="item.name" v-for="(item,index) in tabTypeList" :key="index">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="暂无数据"
            @load="getList"
            class="companyList"
          >
            <div class="subTypesWrap">
              <div class="spanList">
                <span @click="handleTabSub(0,'')" :class="activeTabSub===0?'active': ''">全部</span>
                <span @click="handleTabSub(index+1,item2)" :class="activeTabSub===index+1?'active': ''" v-for="(item2,index) in item.subTypes" :key="index">{{item2&&item2.name}}</span>
              </div>
            </div>
            <li v-for="(item,index) in companyList" :key="index" @click="toDetail(item.id)">
              <div class="imgWrap">
                <img :src="item.photo" alt="">
              </div>
              <div class="companyDetail">
                <p class="peopleName">{{item.name}}</p>
                <div class="honorList">
                  <p>{{item.titleCert}}</p>
                  <p>{{item.practiceCert}}</p>
                </div>
              </div>
            </li>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <TabBar></TabBar>
  </div>
</template>
<script>
import TabBar from '@/components/TabBar'
import { Tab, Tabs, Icon, List } from 'vant';
import { GetTypes, GetList } from '@/api/Expert'
export default {
  name: 'Mine',
  data(){
    return{
      active: '建筑类',
      activeTabSub: 0,
      logoImg: '',
      companyList: [],
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 20,
      params: {
        TypeId: '',
        SkipCount: '',
        MaxResultCount: 20,
        Sorting: 'LastModificationTime desc'
      },
      tabTypeList: this.$store.state.order.tabTypeList&&this.$store.state.order.tabTypeList.length>0?JSON.parse(this.$store.state.order.tabTypeList):[]
    }
  },
  created(){
    if(!this.tabTypeList||this.tabTypeList.length==0){
      this.getType()
    }
    if(this.$route.query.sort){
      this.active = this.$route.query.sort;
      this.currentPage = 0
      this.handleTab(this.active,true)
    }else{
      this.active = '全部'
    }
  },
  methods: {
    handleTabSub(index,row){
      this.activeTabSub = index
      this.params.subTypeId = row.id
      this.companyList = []
      this.currentPage = 0
      this.getList()
    },
    // 跳转到征集函
    toAcquisitionLetter(){
      this.$router.push("/acquisitionLetter")
    },
    // 切换tab
    handleTab(name,state){
      let index = ''
      if(name==='全部'){
        index = 's'
      }else{
        this.tabTypeList.map((item,indexNum)=>{
          if(name===item.name){
            index = indexNum
          }
        })
      }
      if(index!=='s'){
        let id = this.tabTypeList[index].id
        this.params.TypeId = id
      }else{
        this.params.TypeId = '' 
      }
      this.activeTabSub = 0
      this.companyList = []
      this.currentPage = 0
      if(state!==true){
        this.getList()
      }
    },
    getList(){
      this.loading = true
      this.currentPage += 1
      this.params.SkipCount = (this.currentPage - 1) * this.pageSize
      GetList(this.params).then((res)=>{
        this.loading = false
        this.finished = false
        this.companyList.push(...res.items)
        if(this.companyList.length>=res.totalCount){
          this.finished = true
        }
      }).catch(err=>{
      })
    },
    getType(){
      GetTypes().then(res=>{
        this.$store.dispatch('order/setTabTypeList',JSON.stringify(res))
        this.tabTypeList = res
      }).catch(err=>{
      })
    },
    // 跳转到搜索页面
    toSearch(){
      this.$router.push("/search")
    },
    // 跳转到详情
    toDetail(id){
      this.$router.push({
        name: 'TankDetail',
        query:{
          id: id,
        }
      })
    }
  },
  components: {
    TabBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List 
  }
}
</script>
<style lang="less" scoped>
.tank{
  padding-bottom: 80px;
  background: #F3F8FB;
  width: 100vw;
  min-height: 100vh;
  header{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 197px;
    color: #fff;
    background: url(../../assets/img/headDecoration.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    h3{
      font-weight: 300;
      line-height: 50px;
      font-size: 14px;
      height: 50px;
    }
    i{
      font-size: 23px;
      line-height: 50px;
      height: 50px;
    }
    .floatWrap{
      position: absolute;
      top: 54px;
      left: 50%;
      margin-left: -150px;
      width: 300px;
      height: 100px;
    }
  }
  .companyWrap{
    padding: 0 8px;
    .decoration{
      width: 100%;
      height: 160px;
      border-radius: 8px;
    }
    .van-tabs{
      /deep/ .van-tabs__nav{
        justify-content: space-between;
        background: rgba(0, 0, 0, 0);
      }
      /deep/ .van-tab--active{
        .van-tab__text{
          font-size: 18px;
          font-weight: 600;
          color: #202324;
        }
      }
      /deep/ .van-tabs__line{
        background: #C4DBFE;
        width: 38px;
        height: 2px;
        border-radius: 5px;
        bottom: 20px;
      }
      .subTypesWrap{
        margin-bottom: 16px;
        overflow: hidden;
        .spanList{
          overflow-x: auto;
          display: flex;
          span{
            padding: 0 8px;
            height: 20px;
            line-height: 20px;
            background: #EEEEEE;
            border-radius: 20px;
            font-size: 12px;
            color: #2B2E2F;
            margin-right: 12px;
            flex: 1 0 auto;
            text-align: center;
          }
          .active{
            background: #C4DBFE;
            color: #3070E0;
          }
          span:last-child{
            margin-right: 0;
          }
        }
        .spanList::-webkit-scrollbar{
          display:none
        }

      }
      .companyList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 12px;
        li{
          width: 175px;
          margin-bottom: 15px;
          height: 97px;
          display: flex;
          align-items: center;
          background: #fff;
          border-radius: 14px;
          padding: 0 20px;
          box-sizing: border-box;
          .imgWrap{
            width: 58px !important;
            height: 58px !important;
            img{
              width: 58px !important;
              height: 58px !important;
              border-radius: 58px !important;
            }
          }
          .companyDetail{
            margin-left: 8px;
            flex: 1;
          }
          p{
            width: 84px;
            font-size: 13px;
            color: #2B2E2F;
            letter-spacing: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .peopleName{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 4px;
          }
          .honorList{
            font-size: 10px;
            font-weight: 300;
            line-height: 15px;
            height: 30px;
            overflow: hidden;
          }
        }
        /deep/.van-list__finished-text{
          width: 100%;
        }
        /deep/.van-list__loading{
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>