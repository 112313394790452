<template>
  <div class="distribution">
    <van-nav-bar
      title="我想提现"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="inviteWrap">
      <div class="blurBg"></div>
      <div class="inviteContentWrap">
        <div class="contentLeft">
          <p>可用余额</p>
          <p><span>{{user_info.amount}}</span>元</p>
        </div>
        <div class="middle">
          <p>余额</p>
          <div class="line"></div>
        </div>
        <div class="contentRight">
          <img src="@/assets/img/icon/icon_bank.png" alt="">
          <p>银行卡</p>
        </div>
      </div>
    </div>
    <div class="tabWrap">
      <div class="inputWrap">
        <p class="title">￥</p>
        <van-field
          v-model="money"
          name="金额"
          type="digit"
          placeholder="请输入提现金额"
          @input="handleTest"
          maxlength="8"
        />
      </div>
      <p class="note">
        最少提现金额为<span>100元</span>每笔
      </p>
      <ul class="formWrap">
        <li class="formItem">
          <input type="text" placeholder="输入姓名" v-model="userForm.name">
        </li>
        <li class="formItem">
          <input type="text" placeholder="输入账号" v-model="userForm.num">
        </li>
        <li class="formItem">
          <input type="text" placeholder="开户行名称" v-model="userForm.depositName">
        </li>
      </ul>
    </div>
    <p class="withdraw">温馨提示：请确认输入无误再提现</p>
    <!-- 提交按钮 -->
    <button :class="state?'wantBtn btn':'disBtn btn'" :disabled="!state" @click="handleSubmit">提现</button>
  </div>
</template>

<script>
  import { Create } from '@/api/WithdrawRecord'
  import { Icon, NavBar, Field, Toast  } from 'vant';
  export default {
    data(){
      return {
        active: 1,
        money: '',
        userForm: {
          name: '',
          num: '',
          depositName: ''
        },
        state: false,
        offlineList: 10,
        timer: null
      }
    },
    mounted(){
    },
    computed: {
      user_info(){
        return JSON.parse(this.$store.state.order.user_info)
      }
    },
    destroyed(){
      clearTimeout(this.timer)
    },
    watch: {
      money(num){
        if(num<100){
          this.state = false
        }else{
          this.state = true
        }
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
      [Field.name]: Field,
      [Toast.name]: Toast
    },
    methods: {
      handleTest(){},
      // 点击提现
      handleSubmit(){
        if(!this.money){
          Toast.fail('请输入提现金额')
          return 
        }
        if(!this.userForm.name){
          Toast.fail('请输入用户姓名')
          return 
        }
        if(!this.userForm.num){
          Toast.fail('请输入账号')
          return 
        }
        if(!this.userForm.depositName){
          Toast.fail('请输入开户行名称')
          return 
        }
        let params = {
          amount: this.money,
          realName: this.userForm.name,
          bankAccount: this.userForm.num,
          bankName: this.userForm.depositName,
          childBankName: this.userForm.name
        }
        Create(params).then(res=>{
          Toast.success("提现成功")
          // 跳转至分销中
          this.timer = setTimeout((res)=>{
            this.toDistribution()
          },2000)
        }).catch(err=>{
          Toast.fail(err.error.message)
        })
      },
      onClickLeft(){
        this.$router.back(-1)
      },
      toDistribution(){
        this.$router.push({
          name: 'Distributions',
          query:{
            tabNum: 'two'
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.distribution{
  background: #fff;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 40px;
  letter-spacing: 1px;
  .inviteWrap {
    position: relative;
    margin-top: 10px;
    .blurBg {
      background: #e4e4e4;
      box-shadow: 0px 5px 4px 0px #e4e4e4;
      border-radius: 14px;
      border: 1px solid #f5f5f5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: 0px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 112px;
    }
    .inviteContentWrap {
      width: 349px;
      height: 108px;
      background: #fff url("./../../assets/img/mine/hand.png") no-repeat 15%;
      background-size: 70px;
      border-radius: 14px;
      filter: blur(0px);
      margin: 0 auto;
      padding: 24px 20px;
      box-sizing: border-box;
      font-size: 13px;
      color: #868a8b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .contentLeft {
        p{
          color: #2B2E2F;
        }
        span{
          font-weight: bold;
          font-size: 18px;
          color: #202324;
        }
      }
      .middle{
        position: absolute;
        width: 120px;
        left: 50%;
        margin-left: -60px;
        text-align: center;
        .line{
          height: 1px;
          width: 100%;
          background: #D8D8D8;
          margin-top: 4px;
        }
      }
      .contentRight {
        text-align: center;
        img{
          width: 52px;
          height: 36px;
        }
        p{
          font-size: 12px;
          color: #202324;
          line-height: 18px;
        }
      }
    }
  }
  .tabWrap{
    .inputWrap{
      margin-top: 64px;
      margin:64px 60px 0 60px;
      .title{
        color: #000000;
        font-size: 36px;
        margin-bottom: -20px;
        position: relative;
        z-index: 10;
        font-weight: 600;
      }
      /deep/.van-field{
        padding: 0;
        margin-bottom: 5px;
        z-index: 5;
        .van-field__control{
          border-bottom: 1px solid #9C9C9C;
          text-align: center;
          font-size: 57px;
          text-align: center;
          font-weight: 600;
        }
        .van-field__control::-webkit-input-placeholder{
          font-size:20px;
          font-weight: normal;
          line-height: 70px;
        }
      }
    }
    .note{
      color: #2B2E2F;
      font-size: 13px;
      font-weight: 300;
      text-align: center;
      span{
        color: #C14D4D;
      }
    }
    .formWrap{
      margin-top: 26px;
      .formItem{
        margin: 3px 0;
        input{
          display: block;
          width: 277px;
          margin: 0 auto;
          height: 48px;
          background: #F5F5F5;
          border-radius: 24px;
          color: #7B7B7B;
          font-size: 14px;
          padding-left: 24px;
          border: 0;
          box-sizing: border-box;
        }
      }
    }
  }
  .withdraw{
    margin-bottom: 28px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 300;
    color: #2B2E2F;
    line-height: 18px;
    text-align: center;
  }
  .btn{
    display: block;
    width: 322px;
    height: 49px;
    border-radius: 4px;
    margin: 18px auto;
    font-size: 17px;
    font-weight: 500;
    line-height: 49px;
    text-align: center;
    border: 0;
  }
  .wantBtn{
    background: #FAF4DC;
    color: #F2916F;
  }
  .disBtn{
    background: #D3D3D3;
    color: #5C5C5C;
  }
}
</style>