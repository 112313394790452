/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 添加收藏
 * request: /api/v1/favorite/experts
 * method: POST
 */
export const Create = function(data) {
  let path = '/api/v1/favorite/experts';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 获取收藏专家列表
 * request: /api/v1/favorite/experts
 * method: GET
 * @param filter - 
 * eg.
 */
export const GetList = function(queryParams) {
  let path = '/api/v1/favorite/experts';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 移除收藏
 * request: /api/v1/favorite/experts/{expertId}
 * method: DELETE
 * @param expertId - 
 */
export const DeleteByExpertId = function(expertId) {
  let path = '/api/v1/favorite/experts/{expertId}';
  path = path.replace('{expertId}', expertId)
  return request({
    url: path,
    method: 'DELETE',
  })
}
/**
 * 修改收藏
 * request: /api/v1/favorite/experts/{expertId}
 * method: PUT
 * @param expertId - The expert identifier.
 */
export const UpdateByExpertId = function(expertId, data) {
  let path = '/api/v1/favorite/experts/{expertId}';
  path = path.replace('{expertId}', expertId)
  return request({
    url: path,
    method: 'PUT',
    data,
  })
}