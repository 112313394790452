<template>
  <div class="mineWrap">
    <van-nav-bar
      title="我的订单"
      left-arrow
      @click-left="handleBack"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无数据"
        @load="getList"
        class="companyList"
      >
        <li v-for="(item,index) in list" :key="index">
          <div class="itemContent">
            <img :src="item.productInfo.cover" alt="" @click="toDetail(item.id)">
            <div class="itemContentRight">
              <h5>{{item.productInfo.name}}</h5>
              <p>{{item.expertInfo.name}}</p>
              <div>实际付款：{{item.amount}}元</div>
            </div>
          </div>
          <div class="itemAss">
            <span>{{filterState(item.status)}}</span>
            <div>
              <button class="orange" v-if="item.status!==2&&item.status!==1&&item.status!==3" @click="handleDelete(item.id)">删除订单</button>
              <button class="orange" v-if="item.status===1" @click="handleCancel(item.id)">取消订单</button>
              <button @click="toDetail(item.id)">查看项目</button>
              <button @click="toPayOrder(item.id)" v-if="item.status===2">我要付款</button>
            </div>
          </div>
        </li>
      </van-list>
    </div>
  </div>
</template>

<script>
import  { GetList, Delete, Cancel, Pay } from '@/api/Order'
import { Icon, NavBar, Toast, Dialog, List } from 'vant';
import Vue from 'vue';
Vue.use(Toast);
export default {
  name: 'Mine',
  data(){
    return{
      list: [],
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 20,
      params: {
        SkipCount: '',
        MaxResultCount: 20
      }
    }
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [List.name]: List 
  },
  methods: {
    async handlePayH5(id) {
      let res = await Pay(id, { paymentMethod: "WeChatPay", pageType: "H5" });
      res = `${res}&redirect_url=${encodeURIComponent(location.href)}`;
      location.href = res;
    },
    toPayOrder(id){
      this.$router.push({
        name: 'PayOrder',
        query:{
          id: id,
        }
      })
    },
    // 取消项目
    handleCancel(id){
      Dialog.confirm({
        title: '确认取消该项目？',
      }).then(() => {
        Cancel(id).then(()=>{
          Toast("取消成功")
          this.getList()
        }).catch(err=>{
        })
      }).catch(() => {
        Toast("放弃取消")
      });
    },
    // 删除项目
    handleDelete(id){
      Dialog.confirm({
        title: '确认删除该项目？',
      }).then(() => {
        Delete(id).then(()=>{
          this.getList()
          Toast("删除成功")
        }).catch(err=>{
        })
      }).catch(() => {
        Toast("取消删除")
      });
    },
    filterState(state){
      if(state===0){
        return '未知'
      }else if(state===-1){
        return '关闭'
      }else if(state===1){
        return '待确认'
      }else if(state===2){
        return '待支付'
      }else if(state===3){
        return '交易中'
      }else if(state===9){
        return '已完成'
      }
    },
    getList(){
      this.currentPage += 1
      this.params.SkipCount = (this.currentPage - 1) * this.pageSize
      GetList().then(res=>{
        this.loading = true
        this.finished = true
        this.list = res.items
      }).catch(err=>{
      })
    },
    // 返回上级页面
    handleBack(){
      this.$router.back(-1)
    },
    toDetail(id){
      this.$router.push({
        name: 'OrderDetail',
        query:{
          id: id,
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.mineWrap{
  height: 100vh;
  background: #F3F8FB;
  letter-spacing: 1px;
  header{
    height: 50px;
    line-height: 50px;
    padding: 0 23px;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    text-align: center;
    /deep/.van-icon{
      font-size: 16px;
      line-height: 50px;
      position: absolute;
      left: 16px;
      color: #333;
    }
  }
  .content{
    overflow-y: scroll;
    padding-top: 20px;
    .companyList{
      margin-bottom: 10px;
      padding: 0 14px;
      li{
        padding: 22px 13px;
        background: #fff;
        border-radius: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 5px 20px rgba(228,228,228,0.5);
        border-radius: 14px;
        .itemContent{
          display: flex;
          img{
            width: 83px;
            height: 83px;
            border-radius: 8px;
            margin-right: 12px;
          }
          .itemContentRight{
            flex: 1;
            color: #202324;
            letter-spacing: 1px;
            h5{
              font-weight: 600;
              line-height: 30px;
              font-size: 18px;
            }
            p{
              height: 18px;
              font-size: 13px;
              font-weight: 300;
              color: #4E4E4E;
              line-height: 18px;
            }
            div{
              font-size: 10px;
              text-align: right;
              font-weight: 600;
              color: #DC332E;
              line-height: 15px;
            }
          }
        }
        .itemAss{
          display: flex;
          align-items: center;
          margin-top: 24px;
          justify-content: space-between;
          
          span{
            font-size: 13px;
            font-weight: 300;
            color: #4E4E4E;
            line-height: 18px;
          }
          button{
            width: 73px;
            height: 22px;
            border-radius: 8px;
            border: 1px solid #EAEAEA;
            background: #fff;
            color: #202324;
            font-size: 10px;
            font-weight: 600;
            margin-left: 18px;
            letter-spacing: 1.4px;
          }
          .orange{
            background-color: #FAF4DC;
            color: #F2916F;
            border: 0;
          }
        }
      }
    }
  }
}
</style>