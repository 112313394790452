<template>
  <div class="about">
    <van-nav-bar
      title="关于我们"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="aboutContent">
      <div class="blurInfo"></div>
      <div class="content">
        <img src="./../../assets/img/mine/logo.png" alt="">
        <h3>评审专家库</h3>
        <p class="subTitle">专业专注  成就你我</p>
        <p class="note" style="padding: 0 20px;" v-html="app_info.aboutUs"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import { MagicAppInfo } from '@/api/Main'
  import { Icon, NavBar } from 'vant';
  export default {
    computed: {
      user_info(){
        return JSON.parse(this.$store.state.order.user_info)
      },
      app_info(){
        return JSON.parse(this.$store.state.order.app_info)
      }
    },
    mounted(){
      this.getAppInfo()
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
    },
    methods: {
      getAppInfo(){
        MagicAppInfo().then((res) => {
          this.$store.dispatch("order/setAppInfo", JSON.stringify(res))
          this.app_info = res
        }).catch((err) => {
        });
      },
      // 返回上级页面
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.about{
  background: #f3f8fb;
  width: 100vw;
  min-height: 100vh;
  letter-spacing: 1px;
  padding-bottom: 20px;
  .aboutContent{
    .blurBg{
      background: #E4E4E4;
      box-shadow: 0px 5px 4px 0px #E4E4E4;
      border-radius: 14px;
      border: 1px solid #F5F5F5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: 10px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 588px;
    }
    .content{
      box-shadow: 0px 5px 10px 0px rgba(228,228,228,0.5);
      text-align: center;
      margin: 20px;
      background: #fff;
      border-radius: 20px;
      padding-bottom: 20px;
      padding-top: 90px;
      box-sizing: border-box;
      color: #7B7B7B;
      font-size: 14px;
      h3{
        color: #3A3A3A;
        margin-bottom: 4px;
        font-size: 24px;
        font-family: '锐字锐线怒放黑简';
      }
      img{
        width: 94px;
        height: 94px;
        margin-bottom: 24px;
      }
      .note{
        line-height: 19px;
      }
      .subTitle{
        line-height: 19px;
        margin-bottom: 72px;
      }
      .time{
        margin-top: 130px;
      }
    }
  }
}

</style>