<template>
  <div class="tank">
    <div v-if="!loading">
      <van-nav-bar
      title="专家内容页"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <header>
      <div></div>
      <div class="handleWrap">
        <div class="handleItem">
          <img :src="collectState?collectTrueUrl:collectUrl" alt="" class="img2" @click="handleCollect(collectState,tankInfo.id)">
          <span>{{collectState?'取消收藏':'收藏专家'}}</span>
        </div>
      </div>
    </header> 
    <div class="contentWrap">
      <div class="blurInfo" ref="blurInfo"></div>
      <div class="info" ref="info">
        <div class="baseInfo">
          <img :src="tankInfo.photo" alt="头像" class="avatar">
          <div class="infoContent">
            <div class="infoName">
              <strong>{{tankInfo.name}}</strong>
            </div>
            <div class="infoPart">
              <span v-show="tankInfo.titleCert">{{tankInfo.titleCert}}</span>
              <span v-show="tankInfo.practiceCert">{{tankInfo.practiceCert}}</span>
            </div>
            <div class="infoHonar">
              <div v-for="(item,index) in tankInfo.tags&&tankInfo.tags.split('|')" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
        <div class="infoExplain">
          <li v-for="(item,index) in countList&&countList.slice(0,3)" :key="index" v-show="item.num!==0">
            <p class="times"><span>{{item.count}}</span>次</p>
            <p class="expound">{{item.name}}</p>
          </li>
        </div>
      </div>
      <div class="item" style="margin-top: 24px;">
        <h4 v-show="tankInfo.intro">专家简介</h4>
        <p class="intro" v-html="tankInfo.intro"></p>
      </div>
      <div class="item" v-show="tankInfo.products&&tankInfo.products.length>0">
        <div class="title">
          <h4>参加评审会</h4>
        </div>
        <ul class="join">
          <li @click="toMeet(index)" v-for="(item,index) in tankInfo.products" :key="index">
            <img :src="item.cover" alt="">
            <div class="joinDetail">
              <h6>{{item.name}}</h6>
              <p>{{tankInfo.name}}</p>
              <div>
                <span>￥{{item.price}}</span>
                <span>马上预约</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="item">
        <div class="title">
          <h4>推荐企业</h4>
        </div>
        <ul class="enjoy">
          <li v-for="(item,index) in recommendList" :key="index" @click="toDetail(item.id)">
            <img :src="item.cover" alt="">
            <p>{{item.name}}</p>
          </li>
        </ul>
      </div>
    </div>
    </div>
    <van-overlay class="mask" v-else :show="true">
      <van-loading type="spinner" />
    </van-overlay>
  </div>
</template>
<script>
import { Icon, NavBar, Overlay, Loading } from 'vant';
import { Get } from '@/api/Expert'
import { GetRecommendList } from '@/api/Enterprise'
import { Create, DeleteByExpertId } from '@/api/FavoriteExpert'

export default {
  name: 'Mine',
  data(){
    return{
      loading: true,
      tankId: '',
      tankInfo:{},
      recommendList: [],
      collectState: false,
      collectUrl: require('./../../assets/img/icon/icon_collect.png'),
      collectTrueUrl: require('./../../assets/img/icon/icon_collectTrue.png'),
      countList: [
        {
          txt: '可行性研究报告评审',
          num: 0
        }, {
          txt: '参加科研成果鉴定',
          num: 0
        }, {
          txt: '施工组织设计评审',
          num: 0
        }, {
          txt: '建筑施工图初步设计',
          num: 0
        }, {
          txt: '其他类型评审',
          num: 0
        }
      ]
    }
  },
  created(){
    this.tankId = this.$route.query.id;
    this.getDetail()
    this.getRecommendList()
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  methods: {
    toDetail(id){
      this.$router.push({
        name: 'RecommendDetail',
        query:{
          id: id,
        }
      })
    },
    LookMore(txt){
      if(txt==='product'){
        this.$router.push("/case")
      }else if(txt==='recommend'){
        this.$router.push("/recommend")
      }
    },
    getRecommendList(){
      let params = {
        MaxResultCount: 4
      }
      GetRecommendList(params).then(res=>{
        this.recommendList = res.items
      }).catch(err=>{
      })
    },
    getDetail(){
      Get(this.tankId).then(res=>{
        this.tankInfo = res
        this.collectState = res.isFavorite
        this.countList = res.reviewItems
        function sortData(a, b) {
          return b.count - a.count
        }
        this.countList.sort(sortData);
        this.loading = false
      }).catch(err=>{
      })
    },
    // 点击收藏
    handleCollect(state, id){
      if(state){
        this.handleCancelCollect(id)
        return 
      }
      let params = {
        expertId: id,
        remark: ''
      }
      Create(params).then(res=>{
        this.collectState = !this.collectState
      }).catch(err=>{
      })
    },
    // 取消收藏
    handleCancelCollect(id){
      DeleteByExpertId(id).then(res=>{
        this.collectState = !this.collectState
      }).catch(err=>{
      })
    },
    onClickLeft(){
      this.$router.back()
    },
    toMeet(index){
      this.$store.dispatch('order/setTankInfo',JSON.stringify(this.tankInfo))
      this.$store.dispatch('order/setTankProduct',JSON.stringify(this.tankInfo.products[index]))
      this.$router.push({
        name: 'ReviewMeet',
        query:{
          id: this.tankId
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.tank{
  background: #F3F8FB;
  header{
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    background: #fff;
    line-height: 50px;
    h4{
      font-weight: normal;
      letter-spacing: 1px;
      color: #868A8B;
      font-size: 13px;
    }
    .handleWrap{
      color: #2B2E2F;
      font-size: 12px;
      display: flex;
      .handleItem{
        display: flex;
        align-items: center;
        span{
          line-height: 50px;
          margin: 2px 14px 0 6px;
        }
        .img1{
          width: 22px;
          height: 22px;
        }
        .img2{
          width: 24px;
          height: 24px;
        }
      }
    }
    i{
      font-size: 23px;
      line-height: 50px;
      color: #868A8B;
      margin-top: 2px;
    }
  }
  .contentWrap{
    padding: 0 18px 30px;
    background: #fff;
    position: relative;
    // .blurInfo{
    //   background: #E4E4E4;
    //   box-shadow: 0px 5px 4px 0px #E4E4E4;
    //   border-radius: 14px;
    //   border: 1px solid #F5F5F5;
    //   filter: blur(10px);
    //   position: absolute;
    //   left: 0;
    //   top: -10px;
    //   right: 0;
    //   width: 100vw;
    //   box-sizing: border-box;
    //   height: 205px;
    // }
    .info{
      background: #FFFFFF;
      z-index: 99;
      position: relative;
      border-radius: 14px;
      padding: 30px 0 4px;
      box-shadow: 0 0px 20px #eee;
      .transpond{
        position: absolute;
        color: #868A8B;
        font-size: 20px;
        top: 8px;
        right: 8px;
      }
      .baseInfo{
        display: flex;
        padding: 0 20px 0 33px;
        align-items: center;
        .avatar{
          width: 63px;
          height: 63px;
          margin-right: 8px;
          border-radius: 30px;
        }
        .infoContent{
          flex: 1;
          font-size: 12px;
          letter-spacing: 1px;
          .infoName{
            display: flex;
            align-items: center;
            strong{
              font-size: 18px;
              color: #202324;
            }
          }
          .infoPart{
            margin: 4px 0 6px;
            font-size: 13px;
            span{
              margin: 0 4px;
              color: #2B2E2F;
            }
          }
          .infoHonar{
            display: flex;
            flex-wrap: wrap;
            div{
              padding: 2px 4px;
              font-size: 10px;
              border-radius: 8px;
              color: #3070E0;
              background: #C4DBFE;
              margin-right: 8px;
              margin-bottom: 4px;
            }
            div:last-child{
              margin-right: 0;
            }
          }
        }
      }
      .infoExplain{
        display: flex;
        color: #636060;
        margin: 8px 0 12px 0;
        flex-wrap: wrap;
        padding: 0 30px;
        li{
          width: 93px;
          text-align: center;
          padding: 0 14px;
          box-sizing: border-box;
          .times{
            font-size: 10px;
            color: #202324;
            margin: 12px 0 4px;
            span{
              font-size: 18px;
            }
          }
          .expound{
            color: #868A8B;
            font-size: 13px;
          }
        }
      }
    }
    .item{
      margin-top: 12px;
      h4{
        font-weight: normal;
        line-height: 40px;
        color: #868A8B;
        font-size: 15px;
      }
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
          color: #868A8B;
          font-size: 15px;
        }
      }
      .intro{
        font-size: 13px;
        color: #202324;
        font-weight: 300;
        letter-spacing: .4px;
        text-indent: 20px;
        line-height: 20px;
      }
      .enjoy{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
          width: 164px;
          margin-bottom: 8px;
          img{
            width:100%;
            height: 100px;
            border-radius: 12px;
          }
          p{
            color: #202324;
            font-size: 13px;
          }
        }
      }
      .join{
        li{
          display: flex;
          background: #F1F6FB;
          border-radius: 14px;
          border: 1px solid #F1F6FB;
          padding: 5px 8px;
          align-items: center;
          margin-bottom: 3px;
          img{
            width:83px;
            height: 83px;
            border-radius: 12px;
            margin-right: 18px;
          }
          .joinDetail{
            flex: 1;
            h6{
              line-height: 24px;
              color: #202324;
              font-size: 18px;
            }
            p{
              color: #4E4E4E;
              font-size: 13px;    
              line-height: 18px;
              margin-top: 2px;
            }
            div{
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              span:nth-child(1){
                color: #DC332E;
              }
              span:nth-child(2){
                background: #FAF4DC;
                border-radius: 8px;
                color: #F2916F;
                font-size: 10px;
                padding: 4px 8px;            
                font-weight: 600;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
  }
}
/deep/.mask{
  background: rgba(0, 0, 0, 0);
  text-align: center;
  .van-loading{
    margin-top: 120px;
  }
}
</style>