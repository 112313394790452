<template>
  <div class="editInfo">
     <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="onClickLeft">
    </van-nav-bar>
    <div class="infoList">
      <div class="infoState" v-if="delState">
        <img src="@/assets/img/icon/icon_success.png" alt="">
        <span>交易完成</span>
      </div>
      <div class="infoState" v-else>
        <img src="@/assets/img/icon/icon_wait.png" alt="">
        <span>{{filterState(info.status)}}</span>
      </div>
      <van-cell center title="订单编号" :value="info.paymentId"/>
      <van-cell center title="下单时间" :value="info.creationTime"/>
      <van-cell center title="付款时间" :value="info.payTime"/>
      <van-cell center title="确认时间" :value="info.confirmTime"/>
      <van-cell center title="实际付款" :value="info.amount+'元'" class="money"/>
      <van-cell center title="支付方式" :value="filterMethods(info.paymentMethod)" class="payment"/>
      <van-cell center title="订单备注" :value="info.remark" class="noteCell"/>
    </div>
  </div>
</template>

<script>
  import { Icon, NavBar, Field, Uploader, Toast, Cell, Popup, Dialog, Picker } from 'vant';
  import  { Get } from '@/api/Order'
  export default {
    data(){
      return {
        fileList: [],
        fileUploadState: false,
        editPopShow: false,
        sexPopShow: false,
        editTitle:'修改',
        editContent: '',
        info: {
          num: '10232342123111109092',
          orderTime: '2021年9月1日 14:09',
          payTime: '2021年9月2日 09:09',
          confirmTime: '2021年10月1日 10:11',
          money: '2999.00元',
          payment: '支付宝支付',
          note: '2021年8月22日在河南郑州人民剧院2号会议室进行消防建设的风险评估会议'
        },
        orderObj: '',
        delState: false,
        orderId: ''
      }
    },
    components: {
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Toast.name]: Toast,
      [Cell.name]: Cell,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Dialog.Component.name]: Dialog.Component,
    },
    mounted(){
      this.orderId = this.$route.query.id;
      this.getDetail()
    },
    methods: {
      filterState(state){
        if(state===0){
          return '未知'
        }else if(state===-1){
          return '关闭'
        }else if(state===1){
          return '待确认'
        }else if(state===2){
          return '待支付'
        }else if(state===3){
          return '交易中'
        }else if(state===9){
          return '已完成'
        }
      },
      filterMethods(pay){
        if(pay==='AliPay'){
          return '支付宝'
        }else if(pay==='WeChatPay'){
          return '微信'
        }
      },
      // 获取详情
      getDetail(){
        Get(this.orderId).then(res=>{
          this.info = res
          this.delState = res.status===9?true: false
        }).catch(err=>{
        })
      },
      handleConfirmEdit(){
        this.info[this.orderObj] = this.editContent
      },
      // 点击确定选择性别
      onConfirm(value) {
        this.info.sex = value
        this.sexPopShow = false
      },
      // 点击弹性别选择框
      popUp2(){
        this.sexPopShow = true
      },
      // 点击修改信息
      popUp(txt,val,obj){
        this.orderObj = obj
        this.editPopShow = true
        this.editContent = val
        this.editTitle = '修改'+txt
      },
      // 点击头像进行更换头像操作
      handleUpload(){
        if(this.fileUploadState){
          this.fileList = [] 
        }
        this.$nextTick(()=>{
            this.$refs.uploader.chooseFile()
          })
      },
      // 限制上传图片大小
      onOversize(file) {
        Toast('文件大小不能超过 500kb');
      },
      // 上传图片
      afterRead(file) {
        // 此时可以自行将文件上传至服务器
        if(file){
          this.fileUploadState = true
        }
        this.info.avatar = file.content
      },
      // 返回上级页面
      onClickLeft(){
        this.$router.back(-1)
      }
    }
  }
</script>

<style lang="less" scoped>
.editInfo{
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(43, 132, 247, .79) 0%, #F3F8FB 100%, #F3F8FB 100%);
  padding-bottom: 60px;
  /deep/.van-nav-bar{
    background: rgba(0, 0, 0, 0);
    .van-nav-bar__title{
      color: #fff;
    }
    .van-icon{
      color: #fff;
    }
  }
  /deep/.van-hairline--bottom::after{
    border-bottom-width: 0
  }
  .infoList{
    margin: 4px 20px;
    background: #fff;
    border-radius: 14px;
    padding: 30px 0;
    .infoState{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
      span{
        font-size: 17px;
        font-weight: 500;
        color: #343536;
        line-height: 23px;
      }
    }
    .van-cell::after{
      border-width: 0;
    }
    /deep/.van-cell{
      background: rgba(0, 0, 0, 0);
      padding: 22px 16px 22px 16px;
      .van-cell__right-icon{
        line-height: 24px !important;
      }
      .van-cell__title{
        flex: none;
        width: 86px;
        span{
          font-size: 17px;
          font-weight: 500;
          color: #343536;
        }
      }
      .van-cell__value{
        flex: 1;
        font-size: 13px;
      }
    }
    .noteCell{
      align-items: flex-start;
      /deep/.van-cell__value{
        text-align: left;
      }
    }
    .money{
      /deep/.van-cell__value{
        flex: 1;
        font-size: 13px;
        color: #ED3835;
      }
    }
    .payment{
      /deep/.van-cell__value{
        flex: 1;
        font-size: 13px;
        color: #357EDA;
      }
    }
  }
  /deep/.popupWrap{
    padding: 30px;
  }
}
</style>