<template>
  <div class="recommend"> 
    <header>
      <h3 style="font-size:19px;">专家评审库</h3>
      <van-icon name="search" />
    </header>
    <div class="companyWrap">
      <img src="../../assets/img/company.png" class="decoration"/>
      <van-tabs v-model="active">
        <van-tab title="全部">
          <ul class="companyList">
            <li v-for="item in companyList" :key="item">
             <img src="../../assets/img/company.png" alt=""> 
             <p v-for="item2 in item.honorList" :key="item2">{{item2}}</p>
            </li>
          </ul>
        </van-tab>
        <van-tab title="建筑">内容 1</van-tab>
        <van-tab title="结构">内容 2</van-tab>
        <van-tab title="给排水">内容 3</van-tab>
        <van-tab title="暖通">内容 4</van-tab>
        <van-tab title="电气">内容 4</van-tab>
        <van-tab title="施工管理">内容 4</van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Icon } from 'vant';
export default {
  name: 'HomeDetail',
  data(){
    return{
      active: 2,
      companyList: [{
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }, {
        name: '赵春发',
        honorList: ['一级建造师','高级工程师']
      }]
    }
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
  }
}
</script>
<style lang="less" scoped>
.recommend{
  header{
    display: flex;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    line-height: 50px;
    h4{
      font-weight: 600;
    }
    i{
      font-size: 23px;
    }
  }
  .companyWrap{
    padding: 0 14px;
    .decoration{
      width: 100%;
      height: 160px;
      border-radius: 8px;
    }
    .van-tabs{
      /deep/ .van-tabs__nav{
        justify-content: space-between;
        padding: 0;
      }
      /deep/ .van-tab{
        flex: auto;
        padding: 0;
      }
      /deep/ .van-tab--active{
        .van-tab__text{
          font-size: 17px;
          font-weight: 500;
        }
      }
      /deep/ .van-tabs__line{
        background: darkgoldenrod;
        width: 24px;
        height: 5px;
        border-radius: 5px;
        bottom: 5px;
      }
      .companyList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 16px;
        li{
          width: 104px;
          margin-bottom: 18px;
          background: #f4f4f4;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 16px 0;
          border-radius: 18px;
          img{
            width:50px;
            height: 50px;
            border-radius: 50px;
            margin-bottom: 4px;
          }
          p{
            font-size: 8px;
            transform: scale(.8);
            color: #c0c0c0;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
</style>