<template>
  <div class="home"> 
    <div v-if="isIndex">
       <div class="logoWrap">
        <img src="./../../assets/img/mine/logo.png" alt="">
      </div>
      <form class="formWrap">
        <div class="formItem">
          <input type="text" placeholder="输入手机号" v-model="formData.phoneNumber">
        </div>
        <div class="formItem verificationCode">
          <div class="codeLeft">
            <input type="text" placeholder="输入验证码" v-model="formData.captcha">
          </div>
          <button @click.prevent="getCaptcha" class="sendCode" :disabled="isSend">
            <label style="color: #D06767">{{isSend?timeNum:''}}</label>
            {{isSend?'s后重新获取':'获取验证码'}}
          </button>
        </div>
        <div class="formItem">
          <input type="password" placeholder="输入密码" v-model="formData.password">
        </div>
        <div class="formItem">
          <input type="password" @focus="inputOnFocus" placeholder="确认密码" v-model="formData.confirmPassword" @blur="handleTestPassword">
        </div>
        <div class="btnWrap">
          <button @click.prevent="handleRegister">注册</button>
        </div>
        <p @click="toLogin" class="tabLogin">切换到登录</p>
      </form>
    </div>
    <div v-else class="homeIndex">
      <h2>Hi，欢迎使用</h2>
      <div class="logoWrap">
        <img src="./../../assets/img/indexLogo.png" alt="">
      </div>
      <div class="formWrap">
        <div class="btnWrap">
          <button @click.prevent="toLogin">登录</button>
        </div>
        <div class="btnWrap">
          <button class="border" @click.prevent="toRegister">注册</button>
        </div>
        <div class="protocol">
          <van-checkbox v-model="checked"></van-checkbox>
          <div class="protocolText">
            <p>我已经阅读并同意</p>
            <p @click="toProtocol">《专家库服务协议》</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Toast } from 'vant';
import { Get } from '@/api/Captcha'
import { MagicAppInfo } from '@/api/Main'
import { Register } from '@/api/Account';
import { isMobile } from '@/util/validate'
export default {
  name: 'About',
  data(){
    return{
      checked: false,
      isIndex: false,
      btnState: false,
      formData: {
        phoneNumber: '',
        password: '',
        captcha: '',
        confirmPassword: '',
      },
      isSend: false,
      timeNum: 60,
      text: '获取验证码',
      timer: null,
      referrerId: sessionStorage.getItem('referrerId')||''
    }
  },
  mounted(){
    this.getAppInfo()
  },
  components: {
    [Checkbox.name]: Checkbox,
    [Toast.name]: Toast
  },
  methods: {
    inputOnFocus(e) {
      setTimeout(function(){
        e.target.scrollIntoView(true);
      },200);
    },
    toProtocol(){
      this.$router.push("/proto")
    },
    getAppInfo(){
      MagicAppInfo().then((res) => {
        this.$store.dispatch("order/setAppInfo", JSON.stringify(res))
      }).catch((err) => {
      });
    },
    // 验证确认密码
    handleTestPassword(){
      if(!this.formData.password===this.formData.confirmPassword){
        Toast.fail('请输入同样的密码'); 
        return
      }
    },
    toLogin(){
      if(!this.checked){
        Toast.fail('请勾选相关协议');
        return
      }
      this.$router.push("/login")
    },
    toRegister(){
      if(!this.checked){
        Toast.fail('请勾选相关协议');
        return
      }
      this.isIndex = true
    },
    // 获取验证码
    getCaptcha(){
      if(!this.formData.phoneNumber){
        Toast.fail('请输入手机号');
        return false
      }
      if(!isMobile(this.formData.phoneNumber)){
        Toast.fail('请输入正确的手机号');
        return false
      }
      let params = {
        phoneNumberOrEmail: this.formData.phoneNumber,
        type: 'register',
      }
      Get(params).then(res => {
        this.isSend = true
        this.timer = setInterval(()=>{
          this.timeNum-=1
          if(this.timeNum<=0){
            clearInterval(this.timer)
            this.isSend = false
            this.timeNum = 0
          }
        },1000)
      }).catch(err => {
        Toast(err.error.message)
      });
    },
    // 点击注册
    handleRegister(){
      if(!this.formData.phoneNumber||!this.formData.captcha||!this.formData.password){
        Toast.fail('请完善账号信息');
        return
      }
      this.handleTestPassword()
      let params = {
        phoneNumberOrEmail: this.formData.phoneNumber,
        captcha: this.formData.captcha,
        password: this.formData.password,
      }
      if(this.referrerId){
        params.referrerId = this.referrerId
      }
      Register(params).then(res => {
        this.$router.push("/login")
      }).catch(error => {
        Toast.fail(error.message);
      });
    }
  }
}
</script>
<style lang="less" scoped>
.home{
  width: 100vw;
  height: 100vh;
  background: #fff;
  // background: #fff url('./../../assets/img/login/loginBg.png') no-repeat;
  background-size: cover;
  letter-spacing: 1px;
  overflow: hidden;
  .logoWrap{
    text-align: center;
    padding: 113px 0 52px;
    box-sizing: border-box;
    img{
      width: 83px;
      height: 83px;
    }
  }
  .formWrap{
    width: 300px;
    margin: 0 auto 80px;
    .formItem{
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      position: relative;
      label{
        width: 90px;
      }
      button{
        width: 78px;
        margin-left: 8px;
        padding: 4px 8px;
        font-size: 12px;
        position: absolute;
        right: 0;
        border: 1px solid #fff;
        border-radius: 4px;
        background: darkolivegreen;
      }
      .grayBtn{
        background: #d5d5d5;
      }
      input{
        height: 46px;
        border: 0;
        flex: 1;
        padding-left: 26px;
        box-sizing: border-box;
        background: #F5F5F5;
        border-radius: 50px;
      }
      ::-webkit-input-placeholder {
        color: #7B7B7B;
      }
    }
    .verificationCode{
      .codeLeft{
        display: flex;
        align-items: center;
        flex: 1;
        label{
          width: 60px !important;
        }
        input{
          width: 120px;
        }
      }
      .sendCode{
        padding: 4px 10px;
        background: rgba(0, 0, 0, 0);
        border-radius: 8px;
        min-width: 144px;
        height: 40px;
        font-size: 16px;
        border: 0;
        box-sizing: border-box;
        color: #242424;
      }
    }
    .btnWrap{
      text-align: center;
      margin-top: 18px;
      button{
        width: 277px;
        height: 48px;
        background: #056DF6;
        border: 0;
        border-radius: 24px;
        color: #fff;
        font-size: 21px;
      }
      .border{
        background: #fff;
        border: 1px solid #056DF6;
        color: #056DF6;
      }
    }
  }
  .tabLogin{
    color: #7B7B7B;
    font-size: 16px;
    text-align: center;
    margin-top: 18px;
  }
  .homeIndex{
    width: 100vw;
    height: 100vh;
    letter-spacing: 1px;
    overflow: hidden;
    background: #fff url('./../../assets/img/login/loginBg.png') no-repeat;
    background-position-y: 30px;
    background-size: 100% 100%;
    h2{
      padding-left: 47px;
      padding-top: 120px;
      color: rgba(58, 58, 58, 1);
      font-size: 23px;
    }
    .logoWrap{
      padding-top: 69px;
      padding-left: 47px;
      padding-bottom: 30px;
      box-sizing: border-box;
      text-align: left;
      img{
        width: 210px;
        height: 60px;
      }
    }
    .formWrap{
      margin-bottom: 0;
    }
    .btnWrap:nth-child(1){
      margin-top: 0;
    }
    .protocol{
      font-size: 8px;
      padding-left: 14px;
      margin-top: 60px;
      display: flex;
      align-items: center;
      .protocolText{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          color: #7B7B7B;
          margin-left: 7px;
        }
        p:nth-child(2){
          color: #056DF6;
        }
      }
      
      
    }
  }
}
</style>