<template>
  <div class="recommend">
    <header>
      <van-icon name="arrow-left" @click="handleBack" />
      <h3>征集函</h3>
      <a :href="info.recruitFile"><img src="@/assets/img/downloadImg.png" alt=""></a>
    </header>
    <div class="companyWrap" v-html="info&&info.recruitDesc">
      <!-- <div class="letterHead">
        <div class="note">郑建文〔2021〕51号</div>
        <h3>郑州市城乡建设局</h3>
        <h3>关于建立郑州市建设工程勘察设计行业专家库的通知</h3>
      </div>
      <div class="letterContent">
        <div class="note">各勘察设计企业、施工图审查机构</div>
        <div class="note">各有关单位：</div>
        <div class="contentText">
          <p>
            按照《河南省人民政府发布关于向郑州市洛阳市和郑州航空港经济综合实验区下放部分省级经济社会管理权限的通知》(豫政〔2021〕9号)的工作要求，为做好全市建设工程消防设计审查、特殊建设工程特殊消防设计专家评审和超限高层建筑工程抗震设防审批等相关工作，充实建设工程消防设计审查（评审）、超限高层建筑工程抗震设防审批技术力量，发挥行业专家在技术服务、评审论证、工程勘察设计行业双随机检查等方面的重要作用，经研究，决定建立郑州市建设工程勘察设计行业专家库（以下简称专家库）。现将有关事项通知如下：
            一、专家库的专业分类
            根据工作需要，专家库按专业分设建设工程消防设计审查（评审）专业委员会、超限高层建筑工程抗震设防专家委员会、工程勘察设计质量专家委员会等专业委员会。
            二、专家库专家主要工作
            专家库专家主要工作：为建设工程消防设计审查（评审）、超限高层建筑工程抗震设防审批工作提供技术服务；参与我市地方消防、勘察设计行业有关政策方针的制订；参与我市勘察设计行业“双随机、一公开”检查；参与我市特殊建设工程特殊消防设计、超限高层建筑工程抗震设防等专家评审、评选优秀勘察设计作品等。
            三、专家库专家征选范围及条件
            本次专家库面向我市公开征集，市内有关企事业单位、勘察设计单位、施工图审查机构、高等院校、科研机构、行业协会工作人员等均可报名。
            所有入库专家均应熟悉工程建设法律、法规，熟悉基本建设程序；具有良好的职业道德和保密意识，能够认真、公正、诚实、廉洁地履行职责，自觉接受监督管理；身体健康，年龄原则上不超过65岁（享受国务院、省政府特殊津贴的专家或者院士、博士生导师、国家或省工程勘察设计大师，以及工作业绩突出、实践经验丰富的，可适当放宽年龄的要求，但不宜超过70岁或低于35岁）；法律、法规规定的其他条件。除此之外，尚应按以下分类满足相应要求：
            （一）消防设计专家
            1.按专业分类：消防专业、电气专业、给排水专业、建筑专业、结构专业、暖通专业、建筑施工、电力工程专业、化工工程专业、民航工程专业、轨道工程专业、隧道工程专业等。
            2.消防设计专家尚应满足以下条件之一：
            （1）从事消防专业、电气专业、给排水专业、建筑专业、结构专业、暖通专业、电力专业、化工专业等专业教学科研、工程设计、施工图审查（含设计经历）工作15年以上，具有相关专业高级工程师及以上职称和执业资格证书。
            （2）从事地铁、隧道、民航、化工、电力等建设工程设计、施工图审查（含设计经历）工作10年以上，并具有相关专业高级工程师及以上职称和相关专业执业资格证书。
            （3）作为主责承办人或技术复核人承担消防设计审核、验收、火灾调查等工作,具有10年以上相关工作经验。
            （4）从事消防工程设计、施工图审查、技术服务等工作，且具有10年以上工作经验，具有相关专业高级工程师职称且同时具有一级注册消防工程师证书。
            （二）超限高层建筑工程抗震设防专家
            被推荐人员应为长期从事并精通高层建筑工程抗震的勘察、设计、科研、教学和管理的专家，具有相关专业正高级工程师职称，其中勘察、设计企业和施工图审查机构的申报人员应为注册土木工程师（岩土）、一级注册建筑师和一级注册结构工程师，且作为项目负责人（或结构专业负责人）完成2项及以上超限高层建筑工程（项目已建成、竣工验收合格并投用1年以上）。
            （三）房屋建筑工程、市政基础设施工程勘察设计专家
            1.按行业及专业分类：房屋建筑工程相关的勘察、建筑、结构、给排水、电气、暖通、智能化等专业；市政基础设施工程相关的勘察、结构、道路、桥梁、给排水、电气等专业，其中轨道交通工程还应包括桥涵工程、隧道工程、通信信号、铁路轨道等专业；建筑信息化模型（BIM）设计专业。
            2.尚应满足以下条件：具有10年以上工程勘察设计工作经历、且有5项及以上大型工程勘察、设计项目设计或审查的业绩；具备国家（一级）注册执业资格，未实行注册执业资格的专业应具备高级工程师及以上专业技术职称；现任技术职务为甲级工程勘察设计企业副总工程师及以上技术职务或一类施工图审查机构审查人员。
            四、专家入库程序 　(一)公开征集
            入库采取个人申请、单位推荐、市城建局遴选入库的程序。申请入库专家需填报专家申报表一式2份(详见附件1)，并附身份证、学历证书、注册证书、职称证书和担任副总工程师及以上技术职务的任命文件等相关证明材料复印件。
            2021年集中入库专家请于4月2日前将申报材料报送或邮寄到郑州市城乡建设局勘察设计处（郑州市淮河西路25号，16楼1602室），专家申报表和汇总表(详见附件2)电子版发送至zzjwsj@163.com(邮件主题请注明：专家库+单位名称)。
            联系人：魏剑霞       电话：0371-67188925 （二）资源共享
            1.原“郑州市工程勘察设计行业‘双随机’专家库”（包括勘察设计行业监管执法人员、监督检查专业技术人员、资质管理人员等）专家直接并入专家库，无需重复申报。
            2.“河南省超限高层建筑工程抗震设防审查专家委员会”专家由河南省住建厅提供名单直接入库，无需重复申报。
            （三）主动邀请
            因工作需要，市城建局可以主动邀请符合条件的专家，经专家本人同意后审核入库。
            五、专家库的使用
            市城建局应根据具体工作任务和专业需要，在专家库中随机抽取若干名专家，专业配备合理，实行轮换、回避原则。其中，评审或评选等需形成专家意见的，专家人数应为不少于7人的单数，特别复杂的工作应适当增加专家人数；“特殊建设工程特殊消防设计专家评审”专家的抽取，尚应符合《建设工程消防设计审查验收管理暂行规定》（住建部令第51号）第二十一条规定；“双随机、一公开”监督检查专家的抽取，尚应符合相应的“双随机、一公开”监督检查实施细则的要求。
            根据工作需要，开发区、区县（市）建设行政主管部门可以书面申请使用专家库中专业技术专家，市城建局可依申请并按专家自愿参与原则提供相应协助。
            六、专家库的管理
            　(一)市城建局负责专家库的建立与管理，实施动态监管、定期更新。每年组织一次已入库专家信息集中更新。必要时应及时增补相关专业高层次专家入库。
            　(二)有下列情形之一的已入库专家，需向市城建局办理相关手续：
            　1.工作单位、职称、联系电话等个人情况发生变化的，应当在变化发生后的10个工作日内办理书面申请变更手续（详见附件2）。
            　2.因退休、身体健康或其它特殊原因不能继续担任专家工作的，应当由本人及时提出书面申请，退出专家库。　
            3.入库专家如发生违法违纪或其它不适宜（不能）继续担任专家工作的，专家所在单位应及时提出书面报告，经核实后取消相关专家资格。
            （三）入库专家有下列行为之一的，取消专家资格：
            　1.有利用工作之便索取收受被检查对象财物、借机为自己或他人谋取利益等违法、违规行为的;
            　2.徇私舞弊的，或明知与检查对象有利害关系却不及时提出申请回避的;
            　3.不认真履行专家职责，或检查、评审、评选工作出现重大失误的;
            　4.参与检查或专家评审、评选期间及前后发生影响“公开、公平、公正”、泄密或其他不当行为造成恶劣影响的；
            　5.因勘察设计质量造成工程质量事故或引发火灾的；
            6.因施工图审查中漏审、错审工程建设强制性标准条文、地基基础和主体结构的安全性、消防安全性、人防工程防护安全性等内容造成工程质量事故或引发火灾等情况的；
            7.无故不参加有关检查、评审、评选等工作的;
            8.被依法依规责令撤销注册证书、停止执业或吊销注册执业证书的专业技术人员；
            9.被举报存在不适合担任专家工作的情况、且经查证属实的；
            10.法律、法规规定的其他情况。
            （四）对参与检查或专家评审、评选工作中泄密、失职渎职等违法违纪的人员，还将依法依规严肃处理；涉嫌犯罪的，依法移送司法机关。
            附件：1.郑州市建设工程勘察设计行业专家申报表
            2.郑州市建设工程勘察设计行业专家变更申报表
            3.郑州市建设工程勘察设计行业专家申报汇总表
            <br />
            2021年3月25日
          </p>
        </div>
        <div class="loadMore" @click="handleLoadMore" v-show="loadMoreState">
          加载更多…
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Icon, NavBar } from "vant";
import { GetRecruitInfo } from '@/api/Expert'
export default {
  name: "Home",
  data() {
    return {
      loadMoreState: true,
      info: null
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  mounted(){
    this.getRecruitInfo()
  },
  methods: {
    getRecruitInfo(){
      GetRecruitInfo().then((res)=>{
        this.info = res
      }).catch(err=>{
      })
    },
    // 返回上级页面
    handleBack() {
      this.$router.back(-1);
    },
    // 点击加载更多
    handleLoadMore() {
      this.loadMoreState = false;
      document.getElementsByClassName("contentText")[0].style.height = "auto";
    },
    // 跳转到搜索页面
    toSearch() {
      this.$router.push("/search");
    }
  },
};
</script>
<style lang="less" scoped>
.recommend {
  letter-spacing: 1px;
  background: #fff;
  padding-bottom: 80px;
  header {
    display: flex;
    justify-content: center;
    height: 197px;
    background: url(../../assets/img/headDecoration2.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    position: relative;
    h3 {
      line-height: 50px;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
    }
    i {
      font-size: 16px;
      line-height: 50px;
      position: absolute;
      left: 12px;
    }
    img{
      width: 48px;
      height: 48px;
      position: absolute;
      right: 51px;
      bottom: 73px;
    }
  }
  .companyWrap {
    padding: 0 32px;
    margin-top: 20px;
    .letterHead {
      text-align: center;
      color: #202324;
      .note {
        font-size: 13px;
        margin-bottom: 16px;
        font-weight: bold;
      }
      h3 {
        font-size: 18px;
        width: 250px;
        margin: 0 auto;
      }
    }
    .letterContent {
      margin-top: 26px;
      .note {
        font-size: 15px;
        font-weight: 300;
        color: #868a8b;
        line-height: 21px;
      }
      .contentText {
        margin-top: 11px;
        font-size: 13px;
        font-weight: 300;
        color: #202324;
        line-height: 17px;
        height: 620px;
        overflow: hidden;
      }
    }
    .loadMore {
      width: 163px;
      text-align: center;
      margin: 0 auto;
      color: #202324;
      font-size: 13px;
      padding-top: 10px;
      margin-top: 26px;
      border-top: 1px solid #979797;
    }
  }
}
</style>
