/*
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-23 01:28:18
 */
const getters = {
  user_token: (state) => state.order.user_token,
  refresh_token: (state) => state.order.refresh_token,
  user_info: (state) => state.order.user_info,
  app_info: (state) => state.order.app_info,
  tabTypeList: (state) => state.order.tabTypeList,
  tankInfo: (state) => state.order.tankInfo,
  tankProduct: (state) => state.order.tankProduct,
};
export default getters;
