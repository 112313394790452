<template>
  <div class="distribution">
    <van-nav-bar
      title="关注企业"
      left-text=""
      :right-text="rightText"
      left-arrow
      @click-left="onClickLeft"
      @click-right="handleManage"
    />
    <div class="list">
      <van-empty description="暂无关注企业列表" v-if="list&&list.length==0"/>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="itemLeft">
            <img :src="item.enterpriseInfo.cover" alt="">
            <div class="detail">
              <h6>{{item.enterpriseInfo.name}}</h6>
            </div>
          </div>
          <div class="itemRight">
            <span class="del" v-show="rightText==='取消'" @click="handleDelete(item.enterpriseInfo.id)">删除</span>
            <span class="normal" v-show="rightText==='管理'" @click="handleLook(item.enterpriseInfo.id)">查看</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Icon, NavBar, Empty, Toast, Dialog   } from "vant";
import Vue from 'vue';
Vue.use(Toast).use(Empty)
import { GetList, DeleteByEnterpriseId } from '@/api/FavoriteEnterprise'
export default {
  data() {
    return {
      rightText: '管理',
      list: []
    };
  },
  mounted(){
    this.getList()
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  methods: {
    // 点击查看
    handleLook(id){
      this.$router.push({
        name: 'RecommendDetail',
        query:{
          id: id,
        }
      })
    },
    handleDelete(id){
      Dialog.confirm({
        title: '确认移除收藏？',
      }).then(() => {
        DeleteByEnterpriseId(id).then(res=>{
          Toast('移除收藏成功')
          this.getList()
        }).catch(err=>{
        })
      }).catch(() => {
        Toast('取消移除操作')
      });
    },
    getList(){
      GetList().then(res=>{
        this.list = res
      }).catch(err=>{
      })
    },
    // 点击返回
    onClickLeft() {
      this.$router.back(-1);
    },
    // 点击管理
    handleManage(){
      if(this.rightText === '取消'){
        this.rightText = '管理'
      }else{
        this.rightText = '取消'
      }
      
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__text{
  color: #8C8C8C;
}
.distribution {
  background: #f3f8fb;
  width: 100vw;
  min-height: 100vh;
  header {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    line-height: 50px;
    h4 {
      font-weight: normal;
      letter-spacing: 1px;
      color: #868a8b;
      font-size: 13px;
    }
    i {
      font-size: 23px;
      line-height: 50px;
      color: #868a8b;
    }
  }
  .list{
    ul{
      padding: 10px 20px;
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 97px;
        background: #FFFFFF;
        border-radius: 14px;
        margin-bottom: 14px;
        padding: 0 17px;
        padding-right: 10px;
        .itemLeft{
          display: flex;
          align-items: center;
          img{
            width: 58px;
            height: 58px;
            border-radius: 30px;
            margin-right: 12px;
          }
          .detail{
            h6{
              font-size: 14px;
              font-weight: 600;
              color: #2B2E2F;
              line-height: 19px;
              letter-spacing: 1px;
              margin-bottom: 4px;
            }
            p{
              font-size: 10px;
              font-weight: 300;
              color: #2B2E2F;
              line-height: 12px;
              transform: scale(.9);
              margin-left: -4px;
            }
          }
        }
        .itemRight{
          font-size: 15px;
          width: 50px;
          .del{
            color: #DC332E;
          }
          .normal{
            color: #868A8B;
          }
        }
      }
    }
  }
}
</style>
