import request from '@/util/fetch';
import Qs from 'qs'

export const loginByPassword = (phone_number, password) => request({
  url: '/connect/token',
  method: 'post',
  meta: {
    isToken: false
  },
  data: Qs.stringify({
    phone_number,
    password,
    client_id: 'Light_App',
    grant_type: 'phone_password'
  })
})

export const loginByCode = (phone_number, captcha) => request({
  url: '/connect/token',
  method: 'post',
  meta: {
    isToken: false
  },
  data: Qs.stringify({
    phone_number,
    captcha,
    client_id: 'Light_App',
    grant_type: 'phone_code'
  })
})


export const getUserInfo = () => request({
  url: '/connect/userinfo',
  method: 'get'
})

export const refeshToken = (refresh_token) => request({
  url: '/connect/token',
  method: 'post',
  data: Qs.stringify({
    refresh_token,
    client_id: 'Light_App',
    client_secret: '1q2w3e*',
    grant_type: 'refresh_token'
  })
})

export const logout = (id_token_hint, post_logout_redirect_uri) => request({
  url: '/connect/endsession',
  method: 'get',
  params: {
    id_token_hint,
    post_logout_redirect_uri
  }
})
