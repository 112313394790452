/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 获取提现记录
 * request: /api/v1/withdraw-record
 * method: GET
 * @param filter - 
 * @param sorting - 
 * @param skipCount - 
 * @param maxResultCount - 
 * eg.
 */
export const GetRecordList = function(queryParams) {
  let path = '/api/v1/withdraw-record';
  return request({
    url: path,
    method: 'GET',
    params: queryParams,
  })
}
/**
 * 发起提现
 * request: /api/v1/withdraw-record
 * method: POST
 */
export const Create = function(data) {
  let path = '/api/v1/withdraw-record';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}
/**
 * 获取提现
 * request: /api/v1/withdraw-record/{id}
 * method: GET
 * @param id - The identifier.
 * eg.
 */
export const Get = function(id) {
  let path = '/api/v1/withdraw-record/{id}';
  path = path.replace('{id}', id)
  return request({
    url: path,
    method: 'GET',
  })
}