/* eslint-disable */
import request from '@/util/fetch';
/*==========================================================
 *                    HttpStatusCode为400/500时，返回结果请参考RemoteServiceErrorResponse类
 ==========================================================*/
/**
 * 提交意见反馈
 * request: /api/v1/advice
 * method: POST
 */
export const Create = function(data) {
  let path = '/api/v1/advice';
  return request({
    url: path,
    method: 'POST',
    data,
  })
}