<template>
  <div class="tank">
    <van-nav-bar
      title="支付订单"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="contentWrap">
      <div class="blurInfo"></div>
      <div class="info">
        <h6>{{orderInfo.productInfo.name}}</h6>
        <li>
          <img src="./../../assets/img/icon/icon_people.png" alt="" style="width: 15px;height: 17px;">
          <p>专家：{{orderInfo.expertInfo.name}}</p>
        </li>
        <li>
          <img src="./../../assets/img/icon/icon_note.png" alt="" style="width: 17px;height: 17px;">
          <p>{{orderInfo.remark}}</p>
        </li>
        <li>
          <img src="./../../assets/img/icon/icon_contact.png" alt="" style="width: 17px;height: 17px;">
          <p>订单联系人：{{orderInfo.contact}}</p>
        </li>
        <li>
          <img src="./../../assets/img/icon/icon_phone.png" alt="" style="width: 16px;height: 20px;">
          <p>下单手机号：{{orderInfo.contactPhone}}</p>
        </li>
        <div class="total">
          共需支付：<span>{{orderInfo.amount}}元</span>
        </div>
      </div>
    </div>
    <div class="paymentWrap">
      <div class="blurInfo"></div>
      <div class="payment">
        <h4>选择你的支付方式</h4>
        <div class="tabList">
          <div @click="handleTabPay(0)" :class="payActive===0?'active':''">
            <img src="./../../assets/img/icon/pay_wx.png" alt="">
            <p>已选择</p>
          </div>
          <!-- <div  @click="handleTabPay(1)" :class="payActive===1?'active':''">
            <img src="./../../assets/img/icon/pay_ali.png" alt="">
            <p>未选择</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="payBtn" @click="handlePayH5(orderId)">立即支付</div>
  </div>
</template>
<script>
import { Icon, NavBar } from 'vant';
import  { Get, Pay } from '@/api/Order'
export default {
  name: 'Mine',
  data(){
    return{
      payActive: 0,
      orderInfo: null,
      collectState: false,
      orderId: '',
      collectUrl: require('./../../assets/img/icon/icon_collect.png'),
      collectTrueUrl: require('./../../assets/img/icon/icon_collectTrue.png')
    }
  },
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  mounted(){
    this.orderId = this.$route.query.id;
    this.getDetail()
  },
  methods: {
    getDetail(){
      Get(this.orderId).then((res) => {
        this.orderInfo = res
      }).catch((err) => {
      });
    },
    async handlePayH5() {
      let res = await Pay(this.orderId, { paymentMethod: "WeChatPay", pageType: "H5" });
      res = `${res}&redirect_url=${encodeURIComponent('https://m.pszjk.com/#/paySuccess?orderId='+this.orderId)}`;
      location.href = res;
    },
    handleTabPay(index){
      this.payActive = index
    },
    // 点击收藏
    handleCollect(){
      this.collectState = !this.collectState
    },
    onClickLeft(){
      this.$router.back(-1)
    },
    toMeet(){
      this.$router.push('/reviewMeet')
    }
  }
}
</script>
<style lang="less" scoped>
.tank{
  background: #F3F8FB;
  letter-spacing: 1px;
  padding-bottom: 20px;
  .contentWrap{
    padding: 0 13px 30px;
    position: relative;
    margin-top: 20px;
    .blurInfo{
      background: #E4E4E4;
      box-shadow: 0px 5px 4px 0px #E4E4E4;
      border-radius: 14px;
      border: 1px solid #F5F5F5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: -10px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 392px;
    }
    .info{
      background: #FFFFFF;
      z-index: 99;
      position: relative;
      border-radius: 14px;
      padding: 40px 20px 4px;
      height: 369px;
      color: #202324;
      box-sizing: border-box;
      h6{
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
      }
      li{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        img{
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        p{
          flex: 1;
          color: #575757;
          font-size: 15px;
          line-height: 21px;
          font-weight: 600;
        }
      }
      .total{
        font-size: 18px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        span{
          color: #DC332E;
          font-size: 31px;
          margin-left: 20px;
        }
      }
    }
  }
  .paymentWrap{
    padding: 0 13px 30px;
    position: relative;
    box-sizing: border-box;
    .blurInfo{
      background: #E4E4E4;
      box-shadow: 0px 5px 4px 0px #E4E4E4;
      border-radius: 14px;
      border: 1px solid #F5F5F5;
      filter: blur(10px);
      position: absolute;
      left: 0;
      top: -10px;
      right: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 179px;
    }
    .payment{
      background: #FFFFFF;
      z-index: 99;
      position: relative;
      border-radius: 14px;
      padding: 22px 20px 4px;
      color: #202324;
      height: 163px;
      box-sizing: border-box;
      h4{
        text-align: center;
        margin-bottom: 24px;
      }
      .tabList{
        display: flex;
        justify-content: space-around;
        div{
          text-align: center;
          font-size: 10px;
          img{
            width: 56px;
            height: 56px;
            margin-bottom: 10px;
          }
        }
        div:nth-child(1){
          img{
            height: 52px;
            margin-bottom: 14px;
          }
        }
        .active{
          color: #DC332E;
        }
        
      }
    }
  }
  .payBtn{
    width: 277px;
    height: 48px;
    line-height: 48px;
    background: #056DF6;
    border-radius: 24px;
    margin: 0 auto;
    font-size: 21px;
    color: #fff;
    text-align: center;
  }
}
</style>